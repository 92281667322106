import React, {useMemo} from 'react'

import Box from '@material-ui/core/Box'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'

var Minerals = ({onChange, minerals}) => {
  
  return useMemo(() =>
    <>
      {minerals.sort((a, b) => a.name.localeCompare(b.name)).map((mineral, index) => {
        return (
          <Box 
            key={index} 
            display="flex" 
            alignItems="center" 
            flexDirection="row"
            mb={1}
          >
            <Box mr={1} flexShrink={0}>{mineral.name}:</Box> 
            <Input 
              fullWidth
              value={mineral.value}
              endAdornment={<InputAdornment position="end">{mineral.unit}</InputAdornment>}
              onChange={(event) => onChange(
                {
                  name: mineral.name, 
                  unit: mineral.unit, 
                  value: event.target.value
                }, 
                index
              )}
            />
          </Box>
        )
      })}
    </>,
    [minerals] 
  )

}

export default Minerals