import React, {useEffect, useState} from 'react'
import {format} from 'date-fns'

import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'

import Loading from 'components/Loading'

import database from 'database'

var Appointments = () => {
  
  const [loading, setLoading] = useState(true)
  const [appointments, setAppointments] = useState([])

  useEffect(() => {
    database
      .getAppointments()
      .then((data) => {
        setAppointments(data)
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }, [])

  if (loading) return <Loading />
  
  return (
    <Box my={5}>
      <Container maxWidth="xl">
        <Button 
          variant="contained"
          target="_blank"
          href={`/admin/appointments/_/edit`} 
        >
          Nova Consulta
        </Button>

        <br/><br/>

        <Box mb={4}>Listando os últimos 100 resultados</Box>

        {appointments.map((appointment, index) => {
          return (
            <Box 
              key={index} 
              my={3}
              display="flex" 
              alignItems="center"
            >
              <Box>
                <Box><b>Data:</b> {format(appointment.date.toDate(), 'dd/MM/yyyy')}</Box>
                <Box><b>Nome do Aluno: </b>{appointment.user.name}</Box>
                <Box><b>CPF Aluno: </b>{appointment.user.cpf}</Box>
              </Box>

              <Box mb={2} display="flex" justifyContent="flex-end" ml="auto">
                <Box mr={1}>
                  <Button 
                    mr={1}
                    variant="contained"
                    target="_blank"
                    href={`/admin/appointments/${appointment.id}/edit`} 
                  >
                    Editar
                  </Button>
                </Box>

                <Button 
                  variant="contained"
                  target="_blank"
                  href={`/admin/appointments/${appointment.id}/pdf`} 
                >
                  PDF
                </Button>
              </Box>
            </Box>
          )
        })}
      </Container>
    </Box>
  )
  
}

export default Appointments
