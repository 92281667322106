import React from 'react'

import {ButtonPrimary} from '../../ui-kit/Button'
import Animate from '../../ui-kit/Animate'

import {ButtonBlock, TextBlock, TextCenter, Paragraph, Content, Item} from '../../styles'

var Text4 = ({onNext}) => {

  return (
    <Content>
      <TextBlock>
        <TextCenter>
          <Animate duration=".8s" delay=".3s">
            <img src="https://media3.giphy.com/media/MYDN2AYyX1pNTLxuCG/giphy.gif?cid=ecf05e47alqyrqa6j01o5mk7ym4rjvxi60dssvmid2zm3zhu&rid=giphy.gif" width={130} />
          </Animate>
          <Animate duration=".8s" delay="1.3s">
            <Paragraph>
              <Item>
                Como o software está em testes, um grupo <b>de 3 Nutricionistas</b> e <b>1 Personal</b> de forma 
                anônima vão avaliar se o resultado está dentro do nível de confiança.
              </Item>
              <Item>
                Te enviamos o resultado em 24h.
              </Item>
            </Paragraph>
          </Animate>
        </TextCenter>
      </TextBlock>

      <Animate duration=".3s" delay="3s">
        <ButtonBlock>
          <ButtonPrimary onClick={onNext}>
            Vamos começar
          </ButtonPrimary>
        </ButtonBlock>
      </Animate>
    </Content>
  )

}

export default Text4