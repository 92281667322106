import React, {useEffect} from 'react'

import Animate from '../../ui-kit/Animate'

import OptionImage from '../../ui-kit/OptionImage'
import OptionImageHorizontal from '../../ui-kit/OptionImageHorizontal'
import OptionDefault from '../../ui-kit/OptionDefault'
import OptionDefaultHorizontal from '../../ui-kit/OptionDefaultHorizontal'
import OptionText from '../../ui-kit/OptionText'
import OptionTextHorizontal from '../../ui-kit/OptionTextHorizontal'

import {ScrollHorizontal} from '../../styles'

import {Options} from '../../components'

const OptionsSelector = ({
  width, delay, type, position, onChange, question, answers, answersInput, onValid, orientation
}) => {
  var orientation = orientation || 'vertical'

  const {isAnswerSelected, onChangeTextValue, onChangeAnswer, answerTextValue, canSubmit} = Options({
    type,
    position, 
    answersInput, 
    onChange, 
    question
  })

  useEffect(() => onValid(canSubmit()), [answersInput])

  const renderImage = (options) => {
    if (orientation == "horizontal") {
      return <OptionImageHorizontal {...options} />
    } else {
      return <OptionImage {...options} />
    }
  }

  const renderText = (options) => {
    if (orientation == "horizontal") {
      return <OptionTextHorizontal {...options} />
    } else {
      return <OptionText {...options} />
    }
  }

  const renderDefault = (options) => {
    if (orientation == "horizontal") {
      return <OptionDefaultHorizontal {...options} />
    } else {
      return <OptionDefault {...options} />
    }
  }
  
  const answersEl = answers.map((answer, index) => {
    const options = {
      type,
      width,
      id: answer.id,
      label: answer.label,
      value: answer.value,
      src: answer.image,
      placeholder: answer.placeholder,
      valueInput: answerTextValue(answer),
      onChangeInput: (event) => onChangeTextValue(event, answer),
      onChange: () => onChangeAnswer(answer),
      isSelected: isAnswerSelected(answer)
    }

    if (orientation == "horizontal") {
      return (
        <React.Fragment key={index}>
          {answer.type == "image" && renderImage(options)}
          {answer.type == "text" && renderText(options)}
          {answer.type == "default" && renderDefault(options)}
        </React.Fragment>
      ) 
    }
    
    return (
      <Animate 
        key={index} 
        duration="0.5s" 
        delay={`${delay + 0.2 * index}s`}
      >
        {answer.type == "image" && renderImage(options)}
        {answer.type == "text" && renderText(options)}
        {answer.type == "default" && renderDefault(options)}
      </Animate>
    )
  })
  
  if (orientation == "horizontal") {
    return (
      <ScrollHorizontal width={width}>
        {answersEl}
      </ScrollHorizontal>
    )
  } else {
    return answersEl 
  }

}

export default OptionsSelector