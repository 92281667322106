import React, {useMemo} from 'react'

import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

var Formulations = ({onChange, onAppend, onDelete, formulations}) => {
  
  return useMemo(() =>
    <>
      {formulations.map((formulation, index) => {
        return (
          <Box key={index} mb={5}>
            <Box display="flex" justifyContent="flex-end" onClick={() => onDelete(index)}>
              <Button size="small">Remover</Button>
            </Box>

            <Box mb={1}>
              <TextField 
                label="Nome" 
                fullWidth
                placeholder="Ex.: Creatina Monohidratada" 
                onChange={(event) => onChange({name: event.target.value}, index)}
                value={formulation.name}
              />
            </Box>
            <Box mb={1}>
              <TextField 
                label="Composição" 
                fullWidth
                multiline 
                rows={2} 
                onChange={(event) => onChange({composition: event.target.value}, index)}
                value={formulation.composition}
              />
            </Box>
            <Box mb={1}>
              <TextField 
                label="Descrição" 
                fullWidth
                multiline 
                rows={2} 
                onChange={(event) => onChange({description: event.target.value}, index)}
                value={formulation.description}
              />
            </Box>
            <TextField 
              label="Posologia" 
              fullWidth
              multiline  
              onChange={(event) => onChange({posology: event.target.value}, index)}
              value={formulation.posology}
            />
          </Box>
        )
      })}

      <Button 
        onClick={onAppend}
        variant="contained" 
        color="default"
      >
        Adicionar outra
      </Button>
    </>,
    [formulations] 
  )

}

export default Formulations