var uploadFile = (file, onProgress) => {
  const mimeType = getMimeType(file)

  return fetch(`${process.env.API_URL}/upload`, {
    method: 'POST',
    mode: "cors",
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: JSON.stringify({
      size: file.size,
      filename: file.name,
      mimeType
    })
  }).then((response) => {
    return response.json()
  }).then(({uploadUrl, id}) => {
    return uploadToS3(uploadUrl, file, onProgress).then(() => {
      return {
        id,
        filename: file.name,
        size: file.size,
        mimeType
      }
    })
  })
}

var getMimeType = (file) => {
  if (file.type) return file.type
  if (file.name.includes(".mp4")) return 'video/mp4'
  if (file.name.includes(".mov")) return 'video/quicktime'
  return 'application/octet-stream'
}

var uploadToS3 = (s3SignedUrl, file, onProgress) => {
  return sendFile(s3SignedUrl, file, (event) => {
    if (event.lengthComputable && onProgress) {
      onProgress(Math.round(event.loaded / event.total * 100))
    }
  })
}

// https://github.com/github/fetch/issues/89#issuecomment-256610849
var sendFile = (url, file, onProgress) => {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest()
    
    xhr.open("PUT", url)
    xhr.setRequestHeader("Content-Type", getMimeType(file))

    xhr.addEventListener("readystatechange", (event) => {
      if (xhr.status == XMLHttpRequest.UNSENT) {
        reject("Sem conexão com a internet")
      }
    }, false)

    xhr.addEventListener("load", (event) => {
      if (xhr.status >= 400) {
        reject("Formato de arquivo inválido")
      } else if (event.target.responseText) {
        resolve(JSON.parse(event.target.responseText))
      } else {
        resolve()
      }
    }, false)

    xhr.upload.addEventListener("progress", onProgress, false)
    xhr.send(file)
  })
}

export {
  uploadFile
}