import React, {useRef} from 'react'

import Box from '@material-ui/core/Box'
import Radio from '@material-ui/core/Radio'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'

const OptionTextHorizontal = ({id, width, type, label, valueInput, onChange, onChangeInput, isSelected}) => {

  const elRef = useRef(null)

  var element

  if (type == "checkbox") {
    element = (
      <Checkbox
        value={id}
        checked={isSelected}
        onChange={onChange}
        color="primary"        
        style={{
          marginBottom: "10px",
          padding: 0
        }}
      />
    )
  } else {
    element = (
      <Radio
        required
        value={id}
        checked={isSelected}
        onChange={onChange}
        color="primary"
        style={{
          marginBottom: "10px",
          padding: 0
        }}
      />
    )
  }

  return (
    <Box 
      display="flex"
      alignItems="baseline"
      width={`${width / 1.8}px`}
      display="flex"
      flexShrink={0}
      mx={1} 
      px={3} 
      py={2}
      onClick={() => !isSelected && elRef.current.click()}
      style={{
        backgroundColor: "#2d2422",
        borderRadius: "8px",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <FormControlLabel
        ref={elRef}
        label={label}
        control={element}
        style={{
          margin: 0,
          flexDirection: "column",
          alignItems: "baseline"
        }}
      />

      <Box>
        {isSelected && (
          <TextField
            required
            autoFocus
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            label="Digite aqui"
            value={valueInput}
            onChange={onChangeInput}
            style={{marginTop: 10}}
          />
        )}
      </Box>
    </Box>
  )

}

export default OptionTextHorizontal