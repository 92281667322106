import firebase from '../firebase'

const firebaseDatabase = firebase.firestore()

class Database {

  static getTypes = () => {
    return new Promise(async (resolve) => {
      const snapshot = await firebaseDatabase.collection("types").get()
      resolve(snapshot.docs.map((doc) => {return {id: doc.id, ...doc.data()}}))
    })
  }

  static getVideos = () => {
    return new Promise(async (resolve) => {
      const snapshot = await firebaseDatabase.collection("videos").limit(100).orderBy('date', 'desc').get()
      resolve(snapshot.docs.map((doc) => {return {id: doc.id, ...doc.data()}}))
    })
  }

  static getAppointments = () => {
    return new Promise(async (resolve) => {
      const snapshot = await firebaseDatabase.collection("appointments").limit(100).orderBy('date', 'desc').get()
      resolve(snapshot.docs.map((doc) => {return {id: doc.id, ...doc.data()}}))
    })
  }
  
  static getForms = () => {
    return new Promise(async (resolve) => {
      const snapshot = await firebaseDatabase.collection("forms").limit(100).orderBy('date', 'desc').get()
      resolve(snapshot.docs.map((doc) => {return {id: doc.id, ...doc.data()}}))
    })
  }

  static getInvoices = () => {
    return new Promise(async (resolve) => {
      const snapshot = await firebaseDatabase.collection("invoices").orderBy('created', 'desc').get()
      resolve(snapshot.docs.map((doc) => {return {id: doc.id, ...doc.data()}}))
    })
  }

  static getAppointmentsByUser = (userId) => {
    return new Promise(async (resolve, reject) => {
      const snapshot = await firebaseDatabase.collection("appointments")
        .where('user.id', '==', userId)
        .get()

      if (snapshot.empty) {
        reject()
      } else {
        const doc = snapshot.docs[0]
        resolve({id: doc.id, ...doc.data()})
      }
    })
  }

  static getAppointment = (id) => {
    return new Promise(async (resolve, reject) => {
      const snapshot = await firebaseDatabase.collection("appointments")
        .where('id', '==', id)
        .get()

      if (snapshot.empty) {
        reject()
      } else {
        const data = snapshot.docs.map(async (doc) => {
          const data = {id: doc.id, ...doc.data()}
          const snapshotSubCollection = await firebaseDatabase.collection("appointments").doc(doc.id).collection("data").get()
          const dataSubCollection = snapshotSubCollection.docs.map((doc) => { return {id: doc.id, ...doc.data()}})
          
          return {...data, data: dataSubCollection}
        })

        resolve((await Promise.all(data))[0])
      }
    })
  }

  static setAppointment = (id, fields, data) => {
    return new Promise(async (resolve, reject) => {
      const doc = id 
        ? firebaseDatabase.collection(`appointments`).doc(id) 
        : firebaseDatabase.collection(`appointments`).doc()

      
      // Always set id
      const docA = await doc.get()
      await doc.set({...docA.data(), id: doc.id})

      if (fields) {
        await doc.set({...fields, id: doc.id})
      }

      if (data) {
        const ref = await firebaseDatabase.collection(`appointments/${doc.id}/data`).doc('form')
        await ref.set(data)
      }

      resolve({id: doc.id})
    })
  }

  static setForm = (data) => {    
    return new Promise(async (resolve) => {
      const ref = firebaseDatabase.collection(`forms`).doc()
      await ref.set(data)

      resolve({id: ref.id})
    })
  }

  static setVideo = (data) => {    
    return new Promise(async (resolve) => {
      const ref = firebaseDatabase.collection(`videos`).doc()
      await ref.set(data)

      resolve()
    })
  }

}

export default Database