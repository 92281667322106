import {StyleSheet} from '@react-pdf/renderer'

const font = 'Montserrat'
const fontSmall = 20
const fontBase = 24
const fontLarge = 43
const fontXLarge = 76

export default StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'black',
    alignItems: 'center',
    padding: '120 80'
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    width: 80,
    marginBottom: 100
  },
  food: {
    paddingTop: 100
  },
  content: {
    width: '100%'
  },
  foodTh: {
    color: 'gainsboro',
    fontFamily: font,
    fontWeight: 700,
    fontSize: fontSmall,
    marginTop: 20,
    marginBottom: 30
  },
  foodTitle: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    flexDirection: 'row',
    marginBottom: 30
  },
  foodTitleWordHead: {
    fontSize: fontXLarge,
    margin: '2 6 2 0',
    fontFamily: font,
    fontWeight: 800,
    lineHeight: 1,
    color: 'white'
  },
  foodTitleWord: {
    fontSize: fontXLarge,
    margin: '2 6',
    fontFamily: font,
    fontWeight: 800,
    lineHeight: 1,
    color: 'white'
  },
  foodDescription: {
    color: 'gray',
    fontFamily: font,
    fontSize: fontBase,
    fontWeight: 500,
    marginBottom: 20
  },
  foodItem: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 5
  },
  foodItemName: {
    color: 'white',
    fontFamily: font,
    fontSize: fontSmall,
    fontWeight: 700,
  },
  foodItemDescription: {
    color: 'darkgray',
    fontFamily: font,
    fontSize: fontSmall,
    fontWeight: 500,
    margin: '0 3'
  },
  footer: {
    width: "100%",
    position: "absolute",
    bottom: 40,
  },
  footerText: {
    textAlign: 'center',
    fontFamily: font,
    fontWeight: 400,
    fontSize: fontSmall,
    color: 'gray'
  }
})