import React, {useState, useEffect, useRef} from 'react'

import Box from '@material-ui/core/Box'

import {ButtonBlock, TextBlock, Content} from '../../styles'
import {Buttons, ButtonBack, ButtonPrimary, ButtonSecondary} from '../../ui-kit/Button'
import Animate from '../../ui-kit/Animate'

import {OptionsSelector} from '../../components'

var Question1 = ({input, width, answersInput, onBack, onChange, onNext}) => {

	const elRef = useRef(null)
	const [canSubmit1, setCanSubmit1] = useState(null)
	const [canSubmit2, setCanSubmit2] = useState(null)
	const [reference, setReference] = useState(null)

	useEffect(() => {
		if (answersInput[0] && answersInput[0][0]) {
			if (reference) {
				setReference(answersInput[0][0].ref || 2)

				if (!canSubmit2) {
					setTimeout(() => {
						window.scrollTo({top: elRef.current.offsetTop - 50, behavior: 'smooth'})
					}, 300)
				}
			}
		} else {
			if (reference) {
				setReference(answersInput[0][0].ref || 2)
			}
		}
	}, [JSON.stringify(answersInput)])
	
	useEffect(() => {
		if (reference) {
			onChange({
				title: subQuestion,
				position: 1,
				answers: []
			})
		}
  }, [reference])

  const handleSubmit = (event) => {
    event.preventDefault()

    setReference(answersInput[0][0].ref || 2)
	}

	var question
	var subQuestion
	var answers = []
	var subAnswers = []
	var contents = []

	if (input.user.gender == "m") {
		question = "1. Em relação a quantidade de músculos, o que você acha mais bonito?"
		answers = [
			{id: 1, value: "Prefiro ainda menos músculos", type: "default"},
			{id: 2, label: "Selecionar", value: "Músculos 1/3", image: "https://i.imgur.com/A3bJgF2.png", type: "image", ref: 1},
			{id: 3, label: "Selecionar", value: "Músculos 2/3", image: "https://i.imgur.com/hf6PU7c.png", type: "image", ref: 2},
			{id: 4, label: "Selecionar", value: "Músculos 3/3", image: "https://i.imgur.com/jIs2zuY.png", type: "image", ref: 3},
			{id: 5, value: "Prefiro ainda mais músculos", type: "default"}
		]

		subQuestion = "1.2 Quanto a definição, o que você acha mais bonito?"
		subAnswers = [
			{id: 1, ref: 1, type: "default", value: "Prefiro ainda menos definido"},
			{id: 2, ref: 1, type: "image", label: "Selecionar", value: "Definição 1/3", image: "https://i.imgur.com/kTZYJOW.png"},
			{id: 3, ref: 1, type: "image", label: "Selecionar", value: "Definição 2/3", image: "https://i.imgur.com/A3bJgF2.png"},
			{id: 4, ref: 1, type: "image", label: "Selecionar", value: "Definição 3/3", image: "https://i.imgur.com/vPA73s2.png"},
			{id: 5, ref: 1, type: "default", value: "Prefiro ainda mais definido"},
			{id: 6, ref: 1, type: "text", label: "Nenhum, quero digitar...", value: ""},
			{id: 1, ref: 2, type: "default", value: "Prefiro ainda menos definido"},
			{id: 2, ref: 2, type: "image", label: "Selecionar", value: "Definição 1/3", image: "https://i.imgur.com/0ImjjoZ.png"},
			{id: 3, ref: 2, type: "image", label: "Selecionar", value: "Definição 2/3", image: "https://i.imgur.com/hf6PU7c.png"},
			{id: 4, ref: 2, type: "image", label: "Selecionar", value: "Definição 3/3", image: "https://i.imgur.com/BpSQyxt.png"},
			{id: 5, ref: 2, type: "default", value: "Prefiro ainda mais definido"},
			{id: 6, ref: 2, type: "text", label: "Nenhum, quero digitar...", value: ""},
			{id: 1, ref: 3, type: "default", value: "Prefiro ainda menos definido"},
			{id: 2, ref: 3, type: "image", label: "Selecionar", value: "Definição 1/3", image: "https://i.imgur.com/koHiHMl.png"},
			{id: 3, ref: 3, type: "image", label: "Selecionar", value: "Definição 2/3", image: "https://i.imgur.com/jIs2zuY.png"},
			{id: 4, ref: 3, type: "image", label: "Selecionar", value: "Definição 3/3", image: "https://i.imgur.com/TkktsRT.png"},
			{id: 5, ref: 3, type: "default", value: "Prefiro ainda mais definido"},
			{id: 6, ref: 3, type: "text", label: "Nenhum, quero digitar...", value: ""},
		].filter(({ref}) => ref == reference)
	} else {
		question = "1. Em relação a quantidade de músculos, o que você acha mais bonito?"
		answers = [
			{id: 1, value: "Prefiro ainda menos músculos", type: "default"},
			{id: 2, label: "Selecionar", value: "Músculos 1/3", image: "https://i.imgur.com/v6bBSnp.png", type: "image", ref: 1},
			{id: 3, label: "Selecionar", value: "Músculos 2/3", image: "https://i.imgur.com/dJk7U9z.png", type: "image", ref: 2},
			{id: 4, label: "Selecionar", value: "Músculos 3/3", image: "https://i.imgur.com/OkhhzOb.png", type: "image", ref: 3},
			{id: 5, value: "Prefiro ainda mais músculos", type: "default"}
		]

		subQuestion = "1.2 Quanto a definição, o que você acha mais bonito?"
		subAnswers = [
			{id: 1, ref: 1, type: "default", value: "Prefiro ainda menos definido"},
			{id: 2, ref: 1, type: "image", label: "Selecionar", value: "Definição 1/3", image: "https://i.imgur.com/AqICr3u.png"},
			{id: 3, ref: 1, type: "image", label: "Selecionar", value: "Definição 2/3", image: "https://i.imgur.com/v6bBSnp.png"},
			{id: 4, ref: 1, type: "image", label: "Selecionar", value: "Definição 3/3", image: "https://i.imgur.com/Rkwryr5.png"},
			{id: 5, ref: 1, type: "default", value: "Prefiro ainda mais definido"},
			{id: 6, ref: 1, type: "text", label: "Nenhum, quero digitar...", value: ""},
			{id: 1, ref: 2, type: "default", value: "Prefiro ainda menos definido"},
			{id: 2, ref: 2, type: "image", label: "Selecionar", value: "Definição 1/3", image: "https://i.imgur.com/1SCME9K.png"},
			{id: 3, ref: 2, type: "image", label: "Selecionar", value: "Definição 2/3", image: "https://i.imgur.com/dJk7U9z.png"},
			{id: 4, ref: 2, type: "image", label: "Selecionar", value: "Definição 3/3", image: "https://i.imgur.com/2ouPvlw.png"},
			{id: 5, ref: 2, type: "default", value: "Prefiro ainda mais definido"},
			{id: 6, ref: 2, type: "text", label: "Nenhum, quero digitar...", value: ""},
			{id: 1, ref: 3, type: "default", value: "Prefiro ainda menos definido"},
			{id: 2, ref: 3, type: "image", label: "Selecionar", value: "Definição 1/3", image: "https://i.imgur.com/YHFpj7m.png"},
			{id: 3, ref: 3, type: "image", label: "Selecionar", value: "Definição 2/3", image: "https://i.imgur.com/OkhhzOb.png"},
			{id: 4, ref: 3, type: "image", label: "Selecionar", value: "Definição 3/3", image: "https://i.imgur.com/cfn0Dc6.png"},
			{id: 5, ref: 3, type: "default", value: "Prefiro ainda mais definido"},
			{id: 6, ref: 3, type: "text", label: "Nenhum, quero digitar...", value: ""},
		].filter(({ref}) => ref == reference)
	}

	contents.push(
		<form onSubmit={handleSubmit}>
			<Animate duration="1s" delay=".3s">
				<Box fontSize={22} fontWeight={900} lineHeight={1.1} mb={2}>
					{question}
				</Box>
			</Animate>

			<Animate duration="1s" delay="2s">
				<Box display="flex" fontSize={16} mt={4} mb={3}>
					<Box display="flex">
						<img src="https://i.imgur.com/Ix7xeY6.gif" width={20} height={20} />
						<Box ml={1}>Menos músculos</Box>
					</Box>
					<Box ml="auto" display="flex">
						<Box mr={1}>Mais músculos</Box>
						<img src="https://i.imgur.com/bqjmOEb.gif" width={20} height={20} />
					</Box>
				</Box>

				<OptionsSelector
					width={width}
					type="radio"
					orientation="horizontal"
					delay={2}
					position={0}
					onChange={onChange}
					question={question}
					answers={answers}
					answersInput={answersInput}
					onValid={(valid) => setCanSubmit1(valid)}
				/>
			</Animate>

			{!reference && (
				<Animate duration="1s" delay="4s">
					<ButtonBlock>
						<Buttons>
							<ButtonBack onClick={onBack}>
								Voltar
							</ButtonBack>
							{canSubmit1
								? (
									<ButtonPrimary type="submit">
										Próximo ⇣
									</ButtonPrimary>
								)
								: (
									<ButtonSecondary type="button">
										Próximo
									</ButtonSecondary>
								)
							}
						</Buttons>
					</ButtonBlock>
				</Animate>
			)}
		</form>
	) 

	if (reference) {
		contents.push(
			<form onSubmit={onNext} ref={elRef}>
				<Animate duration="1s" delay=".3s">
					<Box fontSize={22} fontWeight={900} lineHeight={1.1} mb={2}>
						{subQuestion}
					</Box>
				</Animate>

				<Animate duration="1s" delay="2s">
					<Box display="flex" fontSize={16} mt={4} mb={3}>
						<Box display="flex">
							<img src="https://i.imgur.com/Ix7xeY6.gif" width={20} height={20} />
							<Box ml={1}>Menos definido</Box>
						</Box>
						<Box ml="auto" display="flex">
							<Box mr={1}>Mais definido</Box>
							<img src="https://i.imgur.com/bqjmOEb.gif" width={20} height={20} />
						</Box>
					</Box>
					
					<OptionsSelector
						width={width}
						type="radio"
						orientation="horizontal"
						delay={2}
						position={1}
						onChange={onChange}
						question={subQuestion}
						answers={subAnswers}
						answersInput={answersInput}
						onValid={(valid) => setCanSubmit2(valid)}
					/>
				</Animate>

				<Animate duration="1s" delay="5s">
					<ButtonBlock>
						<Buttons>
							<ButtonBack onClick={onBack}>
								Voltar
							</ButtonBack>
							{canSubmit2
								? (
									<ButtonPrimary type="submit">
										Próximo →
									</ButtonPrimary>
								)
								: (
									<ButtonSecondary type="button">
										Próximo
									</ButtonSecondary>
								)
							}
						</Buttons>
					</ButtonBlock>
				</Animate>
			</form>
		)
	}

	return (
		<Content>
			<TextBlock>
				{contents.map((content, index) => <div key={index}>{content} <br/><br/></div>)}
			</TextBlock>
		</Content>
	)

}

export default Question1