import {StyleSheet} from '@react-pdf/renderer'

const font = 'Montserrat'
const fontSmall = 20
const fontBase = 22
const fontLarge = 39
const fontXLarge = 73

export default StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'black',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '60 100 40 100'
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    width: 70,
    marginBottom: 15
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 30
  },
  userName: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'row',
    padding: "0 30",
  },
  userNameWord: {
    fontSize: fontXLarge,
    margin: '2 6',
    fontFamily: font,
    fontWeight: 800,
    lineHeight: 1,
    color: 'white'
  },
  date: {
    fontFamily: font,
    fontWeight: 700,
    fontSize: fontSmall,
    color: 'dimgray',
    marginBottom: 20
  },
  days: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    flexDirection: 'row',
    marginTop: 25,
    marginBottom: 15,
    fontFamily: font,
    fontWeight: 600,
    fontSize: fontSmall,
  },
  daysNumber: {
    color: 'white',
    paddingRight: "5",
  },
  daysWeek: {
    color: 'gray'
  },
  periods: {
    width: '100%',
  },
  period: {
    display: "flex",
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginBottom: 22
  },
  periodLeft: {
    width: 280,
    paddingLeft: 40,
  },
  periodRight: {
    paddingLeft: 70,
    paddingTop: 15,
    flexGrow: 1,
  },
  periodTitle: {
    fontSize: fontLarge,
    fontFamily: font,
    fontWeight: 800,
    lineHeight: 1,
    color: 'lightgray'
  },
  periodTime: {
    fontFamily: font,
    fontWeight: 700,
    fontSize: fontBase,
    marginBottom: 10,
    color: 'gray'
  },
  section: {
    marginBottom: 16,
    flexGrow: 1
  },
  sectionLine: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  text: {
    fontFamily: font,
    fontWeight: 700,
    fontSize: fontBase,
    color: 'white'
  },
  quantity: {
    fontFamily: font,
    fontWeight: 500,
    fontSize: fontBase,
    color: 'gainsboro',
    marginLeft: 5
  },
  rainbowWrap: {
    position: 'absolute',
    width: 8,
    height: "100%",
  },
  rainbow: {
    width: "100%",
    borderRadius: 5
  },
  footer: {
    width: "100%",
    marginTop: 'auto'
  },
  footerText: {
    textAlign: 'center',
    fontFamily: font,
    fontWeight: 400,
    fontSize: fontSmall,
    color: 'gray'
  }
})