import React, {useRef} from 'react'

import Box from '@material-ui/core/Box'
import Radio from '@material-ui/core/Radio'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const OptionDefault = ({id, width, isSelected, onChange, type, value}) => {

  const elRef = useRef(null)

  var element

  if (type == "checkbox") {
    element = (
      <Checkbox
        value={id}
        checked={isSelected}
        onChange={onChange}
        color="primary"
        style={{
          marginBottom: "10px",
          padding: 0
        }}
      />
    )
  } else {
    element = (
      <Radio
        required
        value={id}
        checked={isSelected}
        onChange={onChange}
        color="primary"
        style={{
          marginBottom: "10px",
          padding: 0
        }}
      />
    )
  }

  return (
    <Box 
      width={`${width / 1.8}px`}
      display="flex"
      alignItems="center"
      display="flex"
      flexShrink={0}
      mx={1} 
      px={3} 
      py={2}
      onClick={() => elRef.current.click()}
      style={{
        backgroundColor: "#2d2422",
        borderRadius: "8px"
      }}
    >
      <FormControlLabel
        py={2}
        ref={elRef}
        label={value}
        control={element}
        style={{
          margin: 0,
          flexDirection: "column",
          alignItems: "baseline"
        }}
      />
    </Box>
  )

}

export default OptionDefault