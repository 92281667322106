import React, {useEffect, useState, useRef, useCallback} from 'react'
import {useHistory, useLocation, useParams} from 'react-router'
import queryString from 'query-string'

import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles'

import pixel from '../../pixel'

import database from 'database'
import events from 'events'

import {
  Welcome, 
  Text1, Text2, Text3, Text4, Text5,
  Text6, Text7,
  Question1, Question2, Question3, 
  Question4, Question5, Question6, 
  Question7, Question8, Question9, Question10,
  Question11, Question12,
  Complete
} from './components'

import {GlobalStyle, Block} from './styles'

const theme = createMuiTheme({
  typography: {
    fontFamily: "'Montserrat', sans-serif"
  },
  palette: {
    type: 'dark',
    primary: {
      light: '#757ce8',
      main: '#ec4121',
      dark: '#002884',
      contrastText: '#fff',
    },
  }
})

var Form = () => {
  
  let location = useLocation()
  let history = useHistory()
  let params = useParams()

  const {username} = params
  const name = queryString.parse(location.search).name || "Você"
  const gender = queryString.parse(location.search).g || "m"
  const category = queryString.parse(location.search).c || ""
  const bypass = queryString.parse(location.search).bypass
  const step = parseInt(queryString.parse(location.search).step)

  const [page, setPage] = useState(0)
  const [submitting, setSubmitting] = useState(false)
  const [input, setInput] = useState({
    date: new Date(),
    user: {
      username,
      name,
      gender,
      category
    },
    version: '1',
    data: []
  })

  const elRef = useRef(null)

  const handleUnload = useCallback((event) => {
    event.preventDefault()
    event.returnValue = ''
  }, [])

  useEffect(
    () => {
      if (bypass) setPage(step) 
      if (!bypass) setPage(1)
      
      pixel.track({
        event: "FormOpen",
        payload: {
          username,
          gender,
          version: input.version
        }
      })
    }, []
  )

  useEffect(
    () => {
      if (input.data.length == 0) return

      if (input.id) {
        window.removeEventListener("beforeunload", handleUnload)
      } else {
        window.addEventListener("beforeunload", handleUnload)
      }
      return () => window.removeEventListener("beforeunload", handleUnload)
    }, [input]
  )

  useEffect(() => {
    if (page > 0) mapUrlToState()
  }, [location])

  useEffect(() => {
    if (page > 0) {
      replaceUrl(page)
      resetScroll()
    }
  }, [page])

  const resetScroll = () => window.scrollTo({top: 0, behavior: 'smooth'})

  const mapUrlToState = () => setPage(step || 1)

  const replaceUrl = (pageParam) => {
    var state = queryString.parse(location.search)

    history.push({search: queryString.stringify({...state, step: pageParam})})
  }

  const handleInput = (data, position) => {
    setInput(input => {
      const inputData = input.data.find((inputData) => inputData.position == `${position}.${data.position}`)
      
      if (inputData) {
        return {
          ...input,
          data: input.data.map((dataItem) => {
            if (dataItem.position == `${position}.${data.position}`) {
              return {
                ...data,
                position: `${position}.${data.position}`
              }
            } else {
              return dataItem
            }
          })
        }
      } else {
        return {
          ...input,
          data: [
            ...input.data,
            {
              ...data,
              position: `${position}.${data.position}`
            }
          ]
        }
      }
    })
  }

  const handleBack = () => setPage(page + -1)
  const handleNext = (event) => {
    event.preventDefault()

    setPage(page + 1)
  }

  const handleSubmit = (data) => {
    setInput(input => { return {...input, id: data.id}})
  }

  const dataFor = (position) => {
    return input.data
      .filter((data) => data.position.split(".")[0] == `${position}`)
      .map(({answers}) => answers)
  }

  const submit = (event) => {  
    event.preventDefault()

    setSubmitting(true)

    database
      .setForm({
        ...input,
        data: [
          ...input.data.filter(({answers}) => answers.length > 0)
        ]
      })
      .then((data) => {
        pixel.track({
          event: "FormComplete",
          payload: {
            username,
            gender,
            version: input.version
          }
        })

        events.send("Novo questinário enviado")

        handleSubmit(data)
        setPage(page + 1)
        setSubmitting(false)
      })
      .catch(() => {
        setSubmitting(false)

        alert("Ocorreu um erro")
      })
  }
  
  return (
    <ThemeProvider theme={theme}>
      <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap" rel="stylesheet" />
      <GlobalStyle />

      <Block ref={elRef}>
        {page == 1 && <Welcome input={input} onNext={(event) => handleNext(event, 2)} />}
        {page == 2 && <Text1 page={page - 1} pages={6} onNext={handleNext} />}
        {page == 3 && <Text2 page={page - 1} pages={6} onNext={handleNext} />}
        {page == 4 && <Text3 page={page - 1} pages={6} onNext={handleNext} />}
        {page == 5 && <Text4 page={page - 1} pages={6} onNext={handleNext} />}
        {page == 6 && <Text5 page={page - 1} pages={6} onNext={handleNext} />}
        {page == 7 && <Text6 page={page - 1} pages={6} onNext={handleNext} />}
        {page == 8 && <Text7 onNext={handleNext}  />}
        {page == 9 && <Question1 width={elRef.current.offsetWidth} answersInput={dataFor(1)} input={input} onChange={(data) => handleInput(data, 1)} onNext={handleNext} onBack={handleBack} />}
        {page == 10 && <Question2 answersInput={dataFor(2)} input={input} onChange={(data) => handleInput(data, 2)} onNext={handleNext} onBack={handleBack} />}
        {page == 11 && <Question3 answersInput={dataFor(3)} input={input} onChange={(data) => handleInput(data, 3)} onNext={handleNext} onBack={handleBack} />}
        {page == 12 && <Question4 answersInput={dataFor(4)} input={input} onChange={(data) => handleInput(data, 4)} onNext={handleNext} onBack={handleBack} />}
        {page == 13 && <Question5 answersInput={dataFor(5)} input={input} onChange={(data) => handleInput(data, 5)} onNext={handleNext} onBack={handleBack} />}
        {page == 14 && <Question6 answersInput={dataFor(6)} input={input} onChange={(data) => handleInput(data, 6)} onNext={handleNext} onBack={handleBack} />}
        {page == 15 && <Question7 answersInput={dataFor(7)} input={input} onChange={(data) => handleInput(data, 7)} onNext={handleNext} onBack={handleBack} />}
        {page == 16 && <Question8 answersInput={dataFor(8)} input={input} onChange={(data) => handleInput(data, 8)} onNext={handleNext} onBack={handleBack} />}
        {page == 17 && <Question9 answersInput={dataFor(9)} input={input} onChange={(data) => handleInput(data, 9)} onNext={handleNext} onBack={handleBack} />}
        {page == 18 && <Question10 answersInput={dataFor(10)} input={input} onChange={(data) => handleInput(data, 10)} onNext={handleNext} onBack={handleBack} />}
        {page == 19 && <Question11 answersInput={dataFor(11)} input={input} onChange={(data) => handleInput(data, 11)} onNext={handleNext} onBack={handleBack} />}
        {page == 20 && <Question12 answersInput={dataFor(12)} input={input} onChange={(data) => handleInput(data, 12)} loading={submitting} onNext={submit} onBack={handleBack} />}
        {page == 21 && <Complete />}
      </Block>
    </ThemeProvider>
  )
  
}

export default Form
