import React, {useState} from 'react'

import Box from '@material-ui/core/Box'

import {ButtonPrimary} from '../../ui-kit/Button'
import Animate from '../../ui-kit/Animate'

import {ButtonBlock, TextBlock, TextCenter, Paragraph, Content} from '../../styles'

import Logo from 'images/logo-symbol.png'

import {Call, ItemCall} from './styles'

var Begin = ({onNext}) => {

  return (
    <Content>
      <TextBlock>
        <TextCenter>
          <Animate duration=".8s" delay=".3s">
            <Box mb={3}>
              <img src={Logo} width={100} />
            </Box>
          </Animate>

          <Animate duration=".8s" delay="2s">
            <Call>Avaliação Antropométrica <i>Online</i></Call>
          </Animate>

          <Animate duration=".3s" delay="3.5s">
            <Paragraph>Saiba o seu percentual de gordura</Paragraph>
          </Animate>

          <br/><br/>

          <Animate duration=".3s" delay="5s">
            <ItemCall>— Ainda estamos em testes, mas é 100% funcional</ItemCall>
            <ItemCall>— Acompanhe dia a dia a sua evolução (em breve)</ItemCall>
          </Animate>
        </TextCenter>
      </TextBlock>

      <Animate duration=".3s" delay="6s">
        <ButtonBlock>
          <ButtonPrimary onClick={onNext}>
            Começar
          </ButtonPrimary>
        </ButtonBlock>
      </Animate>
    </Content>
  )
  
}

export default Begin