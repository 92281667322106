import React from 'react'

import {Buttons, ButtonPrimary} from '../../ui-kit/Button'
import Animate from '../../ui-kit/Animate'

import {ButtonBlock, TextBlock, Disclaimer, Heading, Paragraph, Item, Content} from '../../styles'

var Text2 = ({onNext, page, pages}) => {

  return (
    <Content>
      <TextBlock>
        <Animate duration=".8s" delay=".3s">
          <Disclaimer>
            Página {page} de {pages}
          </Disclaimer>
          <Heading>
            Como é hoje
          </Heading>
        </Animate>

        <Animate duration=".8s" delay="1s">
          <Paragraph>
            <Animate duration=".8s" delay="1s">
              <Item><b>Nutri não te fala: </b> "Sua alimentação já está 80% ok. Você deveria focar no treino antes.</Item>
            </Animate>
            <Animate duration=".8s" delay="1.5s">
              <Item><b>Personal não fala: </b> "Seu treino está 70% ok. Você deveria arrumar a alimentação antes". </Item>
            </Animate>
            <Animate duration=".8s" delay="2s">
              <Item><b>Ninguém fala: </b> "Olha, pra você talvez o melhor caminho seja 1º focar em hábitos e no psicológico".</Item>
            </Animate>
          </Paragraph>
        </Animate>
      </TextBlock>

      <Animate duration=".3s" delay="3s">
        <ButtonBlock>
          <Buttons>
            <ButtonPrimary onClick={onNext}>
              Próximo
            </ButtonPrimary>
          </Buttons>
        </ButtonBlock>
      </Animate>
    </Content>
  )

}

export default Text2