import React from 'react'

import {Buttons, ButtonPrimary} from '../../ui-kit/Button'
import Animate from '../../ui-kit/Animate'

import {ButtonBlock, TextBlock, TextCenter, SubHeading, Heading, Disclaimer, Paragraph, Item, Content} from '../../styles'

var Text4 = ({onNext, page, pages}) => {

  return (
    <Content>
      <TextBlock>
        <Animate duration=".8s" delay=".3s">
          <Disclaimer>
            Página {page} de {pages}
          </Disclaimer>
          <Heading>
            Você vai nos passar o que te incomoda
          </Heading>
        </Animate>

        <Animate duration=".3s" delay="1s">
          <TextCenter>
            <img src="https://i.imgur.com/oNcikhO.gif" width="100" />
          </TextCenter>
          <br/><br/>
        </Animate>

        <Animate duration=".8s" delay="1.5s">
          <SubHeading>
            <i>"Eu gostaria de ser assim"</i>
          </SubHeading>
        </Animate>

        <Animate duration=".8s" delay="2s">
          <Paragraph>
            <Animate duration=".8s" delay="2.5s">
              <Item>Nós vamos desenhar o melhor caminho pra você resolver isso.</Item>
            </Animate>
            <Animate duration=".8s" delay="3s">
              <Item>Integrando tudo que precisar.</Item>
            </Animate>
          </Paragraph>
        </Animate>
      </TextBlock>

      <Animate duration=".3s" delay="4s">
        <ButtonBlock>
          <Buttons>
            <ButtonPrimary onClick={onNext}>
              Próximo
            </ButtonPrimary>
          </Buttons>
        </ButtonBlock>
      </Animate>
    </Content>
  )

}

export default Text4