import React, {useEffect, useState} from 'react'
import {format} from 'date-fns'

import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'

import Loading from 'components/Loading'

import database from 'database'

var Videos = () => {
  
  const [loading, setLoading] = useState(true)
  const [videos, setVideos] = useState([])

  useEffect(() => {
    database
      .getVideos()
      .then((data) => {
        setVideos(data)
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }, [])

  if (loading) return <Loading />
  
  return (
    <Box my={5}>
      <Container maxWidth="xl">
        <Box mb={4}>Listando os últimos 100 resultados</Box>
        
        {videos.map((video, index) => {
          return (
            <Box 
              key={index} 
              my={3}
              display="flex" 
              alignItems="center"
            >
              <Box>
                <Box><b>Data:</b> {format(video.date.toDate(), 'dd/MM/yyyy')}</Box>
                <Box><b>Instagram:</b>{video.username}</Box>
              </Box>

              <Box mb={2} display="flex" justifyContent="flex-end" ml="auto">
                <Button 
                  variant="contained"
                  target="_blank"
                  href={`${process.env.API_URL}/download/${video.id}?filename=${encodeURIComponent(video.filename)}`} 
                >
                  Baixar Video
                </Button>
              </Box>
            </Box>
          )
        })}
      </Container>
    </Box>
  )
  
}

export default Videos