import React from 'react'

import {ButtonPrimary} from '../../ui-kit/Button'
import Animate from '../../ui-kit/Animate'

import {ButtonBlock, TextBlock, Heading, Disclaimer, Paragraph, Item, Content} from '../../styles'

var Text1 = ({onNext, page, pages}) => {

  return (
    <Content>
      <TextBlock>
        <Animate duration=".8s" delay=".3s">
          <Disclaimer>
            Página {page} de {pages}
          </Disclaimer>
          <Heading>
            Como funciona?
          </Heading>
        </Animate>

        <Animate duration=".8s" delay="2s">
          <Paragraph>
            <Item><b>Todos</b> os métodos de avaliar percentual de gordura tem margem de erro.</Item>
          </Paragraph>
        </Animate>

        <Animate duration=".8s" delay="2.5s">
          <Paragraph>
            <Animate duration=".8s" delay="2.5s">
              <Item>Os nutricionistas te entregam um número, mas nunca te entregam a variação.</Item>
            </Animate>
            <Animate duration=".8s" delay="3s">
              <Item>Mas todos tem variação.</Item>
            </Animate>
            <Animate duration=".8s" delay="3.5s">
              <Item>Geralmente a margem de erro é <b>bem alta</b> (5 a 12%).</Item>
            </Animate>
            <Animate duration=".8s" delay="4s">
              <Item>Coisas pequenas como tomar 500ml de água já afetam qualquer teste.</Item>
            </Animate>
            <Animate duration=".8s" delay="4.5s">
              <Item>Isso estando errado, faz com que o nutricionista erre o cálculo na hora de montar sua dieta.</Item>
            </Animate>
          </Paragraph>
        </Animate>
      </TextBlock>

      <Animate duration=".3s" delay="6s">
        <ButtonBlock>
          <ButtonPrimary onClick={onNext}>
            Que medo!
          </ButtonPrimary>
        </ButtonBlock>
      </Animate>
    </Content>
  )

}

export default Text1