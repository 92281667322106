import React, {useMemo} from 'react'

import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

var Supplementations = ({onChange, onAppend, onDelete, supplementations}) => {
  
  return useMemo(() =>
    <>
     {supplementations.map((supplementation, index) => {
        return (
          <Box key={index} mb={5}>
            <Box mb={1}>
              <Box display="flex" justifyContent="flex-end" onClick={() => onDelete(index)}>
                <Button size="small">Remover</Button>
              </Box>

              <TextField 
                label="Nome" 
                fullWidth
                placeholder="Ex.: Creatina Monohidratada" 
                value={supplementation.name}
                onChange={(event) => onChange({name: event.target.value}, index)}
              />
            </Box>

            <TextField 
              label="Descrição" 
              fullWidth
              multiline 
              rows={2} 
              value={supplementation.description}
              onChange={(event) => onChange({description: event.target.value}, index)}
            />
          </Box>
        )
      })}

      <Button 
        onClick={onAppend}
        variant="contained" 
        color="default"
      >
        Adicionar outra
      </Button>
    </>,
    [supplementations]
  )

}

export default Supplementations