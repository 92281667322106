import React, {useEffect, useState} from 'react'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import DataImport from '../../../../components/DataImport'
import {isEqual, usePrevious} from '../../../../utils'

import {Instructions, MacroNutrients, Vitamins, Minerals, Supplementations, Formulations} from './components'

var Input2 = ({inputGlobal, suggestions, appointment, onChange}) => {

  const [input, setInput] = useState({
    instructions: "",
    energy: "",
    macroNutrients: [],
    minerals: [],
    vitamins: [],
    formulations: [],
    supplementations: []
  })

  const previousInput = usePrevious(input)

  useEffect(() => {
    if (inputGlobal && inputGlobal.data && inputGlobal.data.length > 0 && inputGlobal.data[0].profile) {
      setInputDiff(inputGlobal.data[0].profile, suggestions)
      return
    }

    if (appointment && appointment.data && appointment.data.length > 0 && appointment.data[0].profile) {
      setInputDiff(appointment.data[0].profile, suggestions)
      return
    }

    setInputDiff(input, suggestions)
  }, [appointment])

  useEffect(() => {
    if (previousInput && !isEqual(input, previousInput)) {
      onChange({profile: input})
    }
  }, [input])

  const setInputDiff = ({vitamins, minerals, macroNutrients, ...other}, suggestions) => {
    const vitaminsDiff = [
      ...vitamins,
      ...suggestions.vitamins.filter((vitamin) => !vitamins.map(({id}) => id).includes(vitamin.id)).map((vitamin) => {return {...vitamin, value: ""}}),
    ]

    const mineralsDiff = [
      ...minerals,
      ...suggestions.minerals.filter((mineral) => !minerals.map(({id}) => id).includes(mineral.id)).map((mineral) => {return {...mineral, value: ""}}),
    ]

    const macroNutrientsDiff = [
      ...macroNutrients,
      ...suggestions.macroNutrients.filter((macroNutrient) => !macroNutrients.map(({id}) => id).includes(macroNutrient.id)).map((macroNutrient) => {return {...macroNutrient, value: ""}})
    ]

    setInput({
      ...input, 
      ...other, 
      vitamins: vitaminsDiff, 
      minerals: mineralsDiff, 
      macroNutrients: macroNutrientsDiff
    })
  }

  const handleInput = (data) => {
    setInput(input => {
      return {
        ...input,
        ...data
      }
    })
  }

  const appendNewSupplementation = () => {
    setInput(input => {
      return {
        ...input,
        supplementations: [
          ...input.supplementations,
          {
            name: "", 
            description: ""
          }
        ]
      }
    })
  }

  const appendNewFormulation = () => {
    setInput(input => {
      return {
        ...input,
        formulations: [
          ...input.formulations,
          {
            name: "", 
            composition: "", 
            description: "", 
            posology: ""
          }
        ]
      }
    })
  }

  const handleMineralsInput = (data, position) => {
    setInput(input => {
      return {
        ...input,
        minerals: [
          ...input.minerals.map((item, index) => 
            position == index
            ? {...item, ...data}
            : item
          )
        ]
      }
    })
  }

  const handleMacroNutrientsInput = (data, position) => {
    setInput(input => {
      return {
        ...input,
        macroNutrients: [
          ...input.macroNutrients.map((item, index) => 
            position == index
            ? {...item, ...data}
            : item
          )
        ]
      }
    })
  }

  const handleVitaminsInput = (data, position) => {
    setInput(input => {
      return {
        ...input,
        vitamins: [
          ...input.vitamins.map((item, index) => 
            position == index
            ? {...item, ...data}
            : item
          )
        ]
      }
    })
  }

  const handleSupplementationsInput = (data, position) => {
    setInput(input => {
      return {
        ...input,
        supplementations: [
          ...input.supplementations.map((item, index) => 
            index == position 
            ? {...item, ...data} 
            : item
          )
        ]
      }
    })
  }

  const handleFormulationsInput = (data, position) => {
    setInput(input => {
      return {
        ...input,
        formulations: [
          ...input.formulations.map((item, index) => 
            index == position 
            ? {...item, ...data} 
            : item
          )
        ]
      }
    })
  }

  const removeSupplementation = (position) => {
    setInput(input => {
      return {
        ...input,
        supplementations: [
          ...input.supplementations.filter((_, index) => index != position)
        ]
      }
    })
  }

  const removeFormulation = (position) => {
    setInput(input => {
      return {
        ...input,
        formulations: [
          ...input.formulations.filter((_, index) => index != position)
        ]
      }
    })
  }

  const mapDataImportToState1 = (result) => {
    var inputTransient = {
      energy: "",
      macroNutrients: [],
      vitamins: [],
      minerals: []
    }

    result.data.forEach(({group, ...other}) => {
      switch(group) {
        case "Energia": 
          inputTransient.energy = other.value
          break
        case "Macronutrientes": 
          inputTransient.macroNutrients.push(other)
          break
        case "Vitaminas": 
          inputTransient.vitamins.push(other)
          break
        case "Minerais": 
          inputTransient.minerals.push(other)
          break
      }
    })

    setInput({
      ...input,
      ...inputTransient
    })
  }

  const mapDataImportToState2 = (result) => {
    setInput({
      ...input,
      supplementations: result.data.map(({name, description}) => {
        return {
          id: Math.random(),
          name,
          description
        }
      })
    })
  }

  const mapDataImportToState3 = (result) => {
    setInput({
      ...input,
      formulations: result.data.map(({...other}) => {
        return {
          id: Math.random(),
          ...other
        }
      })
    })
  }

  return (
    <>
      <Instructions 
        instructions={input.instructions} 
        onChange={handleInput}
      />

      <br/><br/>

      <Box mb={2}>
        <DataImport 
          type="2" 
          onConfirm={(data) => mapDataImportToState1(data)}
        />
      </Box>
      
      <Grid container spacing={5}>
        <Grid item xs={4}>
          <Typography variant="h5" gutterBottom>
            Energia e Macronutrientes
          </Typography>

          <br/>
          
          <MacroNutrients 
            macroNutrients={input.macroNutrients}
            energy={input.energy}
            onChange={handleInput}
            onChangeItems={handleMacroNutrientsInput}
          />
        </Grid>
        
        <Grid item xs={4}>
          <Typography variant="h5" gutterBottom>
            Vitaminas
          </Typography>

          <br/>

          <Vitamins 
            vitamins={input.vitamins}
            onChange={handleVitaminsInput}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h5" gutterBottom>
            Minerais
          </Typography>

          <br/>

          <Minerals 
            minerals={input.minerals}
            onChange={handleMineralsInput}
          />
        </Grid>
      </Grid>
      
      <br/><br/>

      <Typography variant="h5" gutterBottom>
        Suplementação
      </Typography>

      <Box mb={2}>
        <DataImport 
          type="3" 
          onConfirm={(data) => mapDataImportToState2(data)}
        />
      </Box>

      <br/>

      <Supplementations
        supplementations={input.supplementations}
        onChange={handleSupplementationsInput}
        onAppend={appendNewSupplementation}
        onDelete={removeSupplementation}
      />

      <br/><br/>

      <Typography variant="h5" gutterBottom>
        Fórmulas manipuladas
      </Typography>

      <Box mb={2}>
        <DataImport 
          type="4" 
          onConfirm={(data) => mapDataImportToState3(data)}
        />
      </Box>

      <br/>

      <Formulations
        formulations={input.formulations}
        onChange={handleFormulationsInput}
        onAppend={appendNewFormulation}
        onDelete={removeFormulation}
      />
    </>
  )
}

export default Input2