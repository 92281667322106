import React, {useMemo} from 'react'

import Box from '@material-ui/core/Box'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'

var Vitamins = ({onChange, vitamins}) => {
  
  return useMemo(() =>
    <>
      {vitamins.map((vitamin, index) => {
        return (
          <Box 
            key={index} 
            display="flex" 
            alignItems="center" 
            flexDirection="row"
            mb={1}
          >
            <Box mr={1} flexShrink={0}>{vitamin.name}:</Box> 
            <Input 
              fullWidth
              value={vitamin.value}
              endAdornment={<InputAdornment position="end">{vitamin.unit}</InputAdornment>}
              onChange={(event) => onChange(
                {
                  name: vitamin.name, 
                  unit: vitamin.unit, 
                  value: event.target.value
                }, 
                index
              )}
            />
          </Box>
        )
      })}
    </>,
    [vitamins] 
  )

}

export default Vitamins