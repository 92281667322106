import React, {useEffect, useState} from 'react'
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles'

import {Begin, Text1, Text2, Text3, Text4, Text5, Upload, Complete} from './components'

import {withRouter} from 'react-router'

import pixel from '../../pixel'

import {GlobalStyle, Block} from './styles'

const theme = createMuiTheme({
  typography: {
    fontFamily: "'Montserrat', sans-serif"
  },
  palette: {
    type: 'dark'
  }
})

var Video = ({match}) => {

  const username = match.params.username
  const [page, setPage] = useState(1)

  useEffect(
    () => {
      pixel.track({
        event: "VideoOpen",
        payload: {
          username
        }
      })
    }, []
  )

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'})
  }, [page])

  const handleNext = (event, page) => {
    event && event.preventDefault()

    setPage(page)
  }

  return (
    <ThemeProvider theme={theme}>
      <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap" rel="stylesheet" />
      <GlobalStyle />

      <Block>
        {page == 1 && <Begin onNext={(event) => handleNext(event, 2)} />}
        {page == 2 && <Text1 page={page - 1} pages={3} onNext={(event) => handleNext(event, 3)} />}
        {page == 3 && <Text2 page={page - 1} pages={3} onNext={(event) => handleNext(event, 4)} />}
        {page == 4 && <Text3 page={page - 1} pages={3} onNext={(event) => handleNext(event, 5)} />}
        {page == 5 && <Text4 onNext={(event) => handleNext(event, 6)} />}
        {page == 6 && <Text5 onNext={(event) => handleNext(event, 7)} />}
        {page == 7 && <Upload onNext={(event) => handleNext(event, 8)} username={username} />}
        {page == 8 && <Complete username={username} />}
      </Block>
    </ThemeProvider>
  )
  
}

Video = withRouter(Video)

export default Video
