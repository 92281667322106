import styled from 'styled-components'

const HeadingsCenter = styled.div`
  display: flex;
  justify-content: center;
`

const Headings = styled.div`
  display: inline-flex;
  flex-direction: column;
`

const HeadingHighlight = styled.div`
  font-size: 55px;
  font-weight: 900;
  padding: 10px 10px;
  background-color: white;
  color: black;
  border-radius: 2px;
  line-height: 1;
  letter-spacing: -4px;
`

const HeadingLarge = styled.div`
  font-weight: 900;
  font-size: 50px;
  line-height: 1;
  letter-spacing: -4px;
  text-transform: uppercase;
`

const HeadingMediumB = styled.div`
  font-weight: 900;
  font-size: 24px;
  line-height: 1.2;
  text-transform: uppercase;
`

const HeadingMedium = styled.div`
  font-weight: 500;
  font-size: 23px;
  line-height: 1.2;
  color: #a097a7;
`

const HeadingSmall = styled.div`
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
`

export {
  Headings,
  HeadingsCenter,
  HeadingHighlight,
  HeadingSmall,
  HeadingLarge,
  HeadingMedium,
  HeadingMediumB
}