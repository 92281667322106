import {StyleSheet} from '@react-pdf/renderer'

const font = 'Montserrat'
const fontSmall = 20
const fontBase = 24
const fontMedium = 33

export default StyleSheet.create({
  page1: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'black',
    padding: '120 60 40 60'
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    width: 80,
    marginBottom: 100
  },
  instructionsHeader: {
    color: 'white',
    fontFamily: font,
    fontWeight: 700,
    fontSize: fontMedium,
    color: 'white',
    margin: '10 0 25 0',
    textTransform: "uppercase"
  },
  instructions: {
    width: '100%',
    color: '#C6C6C5',
    fontFamily: font,
    fontSize: fontBase,
    lineHeight: 1.4
  },
  page2: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'black',
    padding: '120 60 40 60'
  },
  logo2: {
    width: 80,
    marginBottom: 50
  },
  content2: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: "100%"
  },
  others: {
    display: 'flex',
    flexDirection: 'row'
  },
  other: {
    width: '30%',
    color: 'white',
    padding: '0 10'
  },
  other1: {
    width: '40%',
    color: 'white',
    padding: '0 10'
  },
  otherTitle: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    color: 'white',
    fontFamily: font,
    height: 60,
    fontWeight: 700,
    fontSize: fontMedium,
    marginBottom: 15
  },
  otherItem: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 5
  },
  otherItemName: {
    color: 'darkgrey',
    fontFamily: font,
    fontSize: fontBase,
    marginRight: 10
  },
  otherItemValue: {
    color: 'white',
    fontFamily: font,
    fontWeight: 600,
    fontSize: fontBase,
  },
  formulations: {
    margin: "20 0"
  },
  formulation: {
    marginTop: "30"
  },
  formulationTitle: {
    fontFamily: font,
    fontWeight: 800,
    fontSize: fontMedium,
    marginBottom: 15,
    color: 'white'
  },
  formulationName: {
    color: 'white',
    fontFamily: font,
    fontSize: fontBase,
    fontWeight: 700,
    marginBottom: 5
  },
  formulationComposition: {
    color: 'darkgrey',
    fontFamily: font,
    fontSize: fontBase,
    fontWeight: 600,
    margin: "3 0"
  },
  formulationDescription: {
    color: 'gray',
    fontFamily: font,
    fontSize: fontBase,
    margin: "3 0",
    lineHeight: 1.3
  },
  formulationPosology: {
    color: 'darkgrey',
    fontFamily: font,
    fontSize: fontBase,
    marginTop: "3",
    fontWeight: 500,
  },
  supplementations: {
    margin: "20 0"
  },
  supplementation: {
    marginTop: "30"
  },
  supplementationTitle: {
    fontFamily: font,
    fontWeight: 800,
    fontSize: fontMedium,
    marginBottom: 15,
    color: 'white'
  },
  supplementationName: {
    color: 'white',
    fontFamily: font,
    fontSize: fontBase,
    fontWeight: 700,
    marginBottom: 5
  },
  supplementationDescription: {
    color: 'gray',
    fontFamily: font,
    fontSize: fontBase,
    fontWeight: 600,
    lineHeight: 1.3,
    margin: "3 0"
  },
  footer: {
    width: "100%",
    marginTop: 'auto'
  },
  footerText: {
    textAlign: 'center',
    fontFamily: font,
    fontWeight: 400,
    fontSize: fontSmall,
    color: 'gray'
  }
})