import React from 'react';
import {Page, Text, View, Image} from '@react-pdf/renderer'

import Logo from 'images/logo-symbol.png'

import styles from './styles'

const Pdf2 = ({appointment}) => {

  const data = appointment.data[0].profile

  return (
    <>
      <Page
        size={{width: 1080, height: 1920}} 
        style={styles.page1} 
        wrap
      >
        <View style={styles.header} fixed>
          <Image 
            src={Logo}
            style={styles.logo} 
          />
        </View>

        <View style={styles.others}>
          <View style={styles.other1}>
            <View style={styles.otherTitle}>
              <Text>Energia e</Text>
              <Text>Macronutrientes</Text>
            </View>

            <View style={styles.otherItem}>
              <Text style={styles.otherItemName}>Energia</Text> 
              <Text style={styles.otherItemValue}>{data.energy} kcal</Text>
            </View>

            {data.macroNutrients.sort((a, b) => a.sort > b.sort ? 1 : -1).map((macroNutrient, index) => {
              return (
                <View key={index} style={styles.otherItem}>
                  <Text style={styles.otherItemName}>{macroNutrient.name}</Text>
                  <Text style={styles.otherItemValue}>{macroNutrient.value} {macroNutrient.unit}</Text>
                </View>
              )
            })}
          </View>
          <View style={styles.other}>
            <View style={styles.otherTitle}>
              <Text>Vitaminas</Text>
            </View>

            {data.vitamins.sort((a, b) => a.name.localeCompare(b.name)).map((vitamin, index) => {
              return (
                <View key={index} style={styles.otherItem}>
                  <Text style={styles.otherItemName}>{vitamin.name}</Text>
                  <Text style={styles.otherItemValue}>{vitamin.value} {vitamin.unit}</Text>
                </View>
              )
            })}
          </View>
          <View style={styles.other}>
            <View style={styles.otherTitle}>
              <Text>Minerais</Text>
            </View>

            {data.minerals.sort((a, b) => a.name.localeCompare(b.name)).map((mineral, index) => {
              return (
                <View key={index} style={styles.otherItem}>
                  <Text style={styles.otherItemName}>{mineral.name}</Text>
                  <Text style={styles.otherItemValue}>{mineral.value} {mineral.unit}</Text>
                </View>
              )
            })}
          </View>
        </View>
        
        {data.supplementations.length > 0 &&
          <View style={styles.supplementations}>
            <Text style={styles.supplementationTitle}>Suplementação</Text>

            {data.supplementations.map((supplementation, index) => {
              return (
                <View key={index} style={styles.supplementation} wrap={false}>
                  <Text style={styles.supplementationName}>{index + 1}) {supplementation.name}</Text>
                  <Text style={styles.supplementationDescription}>{supplementation.description}</Text>
                </View>
              )
            })}
          </View>
        }
        
        {data.formulations.length > 0 &&
          <View style={styles.formulations}>
            <Text style={styles.formulationTitle}>Fórmulas Manipuladas</Text>

            {data.formulations.map((formulation, index) => {
              return (
                <View key={index} style={styles.formulation} wrap={false}>
                  <Text style={styles.formulationName}>{index + 1}) {formulation.name}</Text>
                  <Text style={styles.formulationComposition}>{formulation.composition}</Text>
                  <Text style={styles.formulationDescription}>{formulation.description}</Text>
                  <Text style={styles.formulationPosology}>🕐 Posologia: {formulation.posology}</Text>
                </View>
              )
            })}
          </View>
        }

        <View style={styles.footer} fixed>
          <Text style={styles.footerText}>
            {appointment.nutritionists.map((nutritionist, index) => {
              return (
                <Text key={index}>
                  {nutritionist.name} {nutritionist.code} {index + 1 < appointment.nutritionists.length && " e "}
                </Text>
              )
            })}
          </Text>
        </View>
      </Page>

      <Page 
        size={{width: 1080, height: 1920}} 
        style={styles.page2} 
        wrap
      >
        <View style={styles.header} fixed>
          <Image 
            src={Logo}
            style={styles.logo2} 
          />
        </View>

        <View style={styles.content2}>
          <Text style={styles.instructionsHeader}>
            Orientações Nutricionais
          </Text>
          
          <Text style={styles.instructions} wrap>
            {data.instructions}
          </Text>
        </View>

        <View style={styles.footer} fixed>
          <Text style={styles.footerText}>
            {appointment.nutritionists.map((nutritionist, index) => {
              return (
                <Text key={index}>
                  {nutritionist.name} {nutritionist.code} {index + 1 < appointment.nutritionists.length && " e "}
                </Text>
              )
            })}
          </Text>
        </View>
      </Page>
    </>
  )
}

export default Pdf2