import React, {useState} from 'react'

import Animate from '../../ui-kit/Animate'
import {ButtonPrimary} from '../../ui-kit/Button'

import {uploadFile} from '../../../../uploader'

import {Video, ButtonBlock, TextBlock, TextCenter, Content, Paragraph, Item, Heading} from '../../styles'
import {UploadInput} from './styles'

import pixel from '../../../../pixel'

import database from 'database'
import events from 'events'

var Upload = ({username, onNext}) => {

  const [input, setInput] = useState({
    username
  })

  const [status, setStatus] = useState({
    state: "default",
    progress: 0
  })

  const handleUpload = (file) => {
    pixel.track({
      event: "VideoUploading"
    })

    uploadFile(file, (percentage) => {
      setStatus({
        ...status,
        state: "uploading",
        progress: percentage
      })
    })
    .then(handleFileUpload)
  }

  const handleFileUpload = (data) => {
    setInput({
      ...input,
      ...data
    })
    setStatus({...status, state: "uploaded"})
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    
    setStatus({...status, state: "submitting"})

    database
      .setVideo({...input, date: new Date()})
      .then(() => {
        pixel.track({
          event: "VideoUploaded"
        })
        
        events.send("Nova avaliação antropométrica enviada")

        onNext()
      })
      .catch(() => setStatus({...status, state: "error"}))
  }

  if (status.state == "error") {
    return (
      <>Ocorreu um erro</>
    )
  }

  if (status.state == "default" || status.state == "uploading") {
    return (
      <Content>
        <TextBlock>
          <Animate duration=".8s" delay=".3s">
            <Heading>
              Instruções
            </Heading>
          </Animate>

          <Animate duration="1s" delay="1s">
            <Video poster="https://media0.giphy.com/media/cnzP4cmBsiOrccg20V/giphy.gif" autoPlay muted loop playsInline controls={false}>
              <source src="https://i.imgur.com/PJzwfcq.mp4" type="video/mp4" />
            </Video>
            <br/><br/>
          </Animate>

          <Animate duration=".3s" delay="3s">
            <Paragraph>
              <Item>
                1. Lembre da posição
                <br/><br/>
                {'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'} ☀️ {'\u00A0'}→ {'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}  🤳 {'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'} → {'\u00A0'} 👱‍♀
                <br/>
                {'\u00A0'}{'\u00A0'} Luz → Câmera → Você
              </Item>
              <Item>2. Câmera na altura do peito</Item>
              <Item>3. Tem que aparecer no mínimo da panturrilha até o pescoço</Item>
            </Paragraph>
          </Animate>
        </TextBlock>

        <Animate duration=".3s" delay="5s">
          <ButtonBlock>
            <ButtonPrimary type="submit">
              <UploadInput
                type="file" 
                accept="image/*;capture=camera" 
                onChange={(event) => {
                  handleUpload(event.target.files[0])
                }}
              />

              {status.state == "default" && "Gravar ou escolher um vídeo"}
              {status.state == "uploading" && `Aguarde, ${status.progress} %`}
            </ButtonPrimary>
          </ButtonBlock>
        </Animate>
      </Content>
    )
  } else {
    return (
      <>
        <Content>
          <form onSubmit={handleSubmit}>
            <Animate duration=".3s" delay=".3s">
              <TextBlock>
                <TextCenter>
                  <img src="https://media2.giphy.com/media/SRwzoWGdwpBRm1z7cj/giphy.gif?cid=ecf05e47e6cd24b39a44dab27403f6c708b6a8b67a986c37&rid=giphy.gif" width="100" />
                  <br/><br/>
                  <Heading>Vídeo carregado!</Heading>
                  <Paragraph>
                    Por favor, clique em confirmar abaixo
                  </Paragraph>
                </TextCenter>
              </TextBlock>
            </Animate>

            <Animate duration=".3s" delay="1s">
              <ButtonBlock>
                {status.state == "uploaded" && <ButtonPrimary type="submit">Confirmar</ButtonPrimary>}
                {status.state == "submitting" && <ButtonPrimary>Confirmando...</ButtonPrimary>}
              </ButtonBlock>
            </Animate>
          </form>
        </Content>
      </>
    )
  }
  
}

export default Upload
