import React, {useRef, useCallback, useMemo, useEffect, useState} from 'react'

import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Autocomplete from '@material-ui/lab/Autocomplete'

const Item = ({item, times, periods, meals, portions, handleItemsInput, removeItem}) => {

  return useMemo(() =>
    <Box 
      display="flex" 
      alignItems="center" 
      flexDirection="row"
      mb={2}
    >
      <Box flexGrow={1} mr={1}>
        <Autocomplete
          onChange={(event, value) => {
            handleItemsInput({type: "time", value}, item.id)
          }}
          getOptionSelected={(option, value) => option.id == value.id}
          options={times}
          getOptionLabel={(option) => option.value}
          value={item.time}
          renderInput={(params) => <TextField {...params} label="Horário" size="small" variant="outlined" />}
        />
      </Box>
      
      <Box flexGrow={1} mr={1}>
        <Autocomplete
          autoSelect
          onChange={(event, value) => {
            handleItemsInput({type: "period", value}, item.id)
          }}
          options={periods}
          getOptionSelected={(option, value) => option.id == value.id}
          getOptionLabel={(option) => option.name}
          value={item.period}
          renderInput={(params) => <TextField {...params} label="Período" size="small" variant="outlined" />}
        />
      </Box>
      
      <Box flexGrow={1} mr={1}>
        <Autocomplete
          autoSelect
          onChange={(event, value) => {
            handleItemsInput({type: "meal", value}, item.id)
          }}
          options={meals}
          getOptionSelected={(option, value) => option.id == value.id}
          getOptionLabel={(option) => option.name}
          value={item.meal}
          renderInput={(params) => <TextField {...params} label="Alimento" size="small" variant="outlined" />}
        />
      </Box>
      
      <Box flexGrow={1} mr={1}>
        <Autocomplete
          onChange={(event, value) => {
            handleItemsInput({type: "portion", value}, item.id)
          }}
          value={item.portion}
          getOptionSelected={(option, value) => option.id == value.id}
          options={portions}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} label="Porção" variant="outlined" size="small" />}
        />
      </Box>

      <Button 
        variant="contained" 
        color="default"
        onClick={() => removeItem(item.id)}
      >
        Remover
      </Button>
    </Box>, 
    [item]
  )
}

var Items = (props) => {
  
  const isInitialMount = useRef(true)

  const buildNewItem = () => {
    return {
      id: Math.random(),
      period: {
        id: "",
        name: ""
      }, 
      time: {
        id: "",
        value: ""
      }, 
      meal: {
        id: "",
        name: ""
      }, 
      portion: {
        id: "",
        name: ""
      }
    }
  }
  
  const [input, setInput] = useState({
    variant: "",
    days: "",
    items: [buildNewItem()]
  })

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      props.onChange(input)
    }
  }, [input])

  useEffect(() => {
    props.input && setInput(props.input)
  }, [props.input])

  const removeItem = useCallback((id) => {
    setInput(input => {
      return {
        ...input,
        items: [
          ...input.items.map((item) => 
            item.id == id
            ? null 
            : item
          )
        ].filter(Boolean)
      }
    })
  }, [])

  const appendNewItem = useCallback(() => {
    setInput(input => {
      return {
        ...input,
        items: [
          ...input.items,
          buildNewItem()
        ]
      }
    })
  }, [])

  const handleInput = useCallback((data) => {
    setInput(input => {
      return {
        ...input,
        ...data
      }
    })
  }, [])
  
  const handleItemsInput = useCallback(({type, value}, id) => {
    setInput(input => {
      return {
        ...input,
        items: [
          ...input.items.map((item) => 
            item.id == id
            ? {...item, [type]: {...value}} 
            : item
          )
        ]
      }
    })
  }, [])

  return (
    <>
      <Box mb={1}>
        <TextField 
          label="Variante do Plano" 
          onChange={(event) => handleInput({variant: event.target.value})}
          value={input.variant}
        />
      </Box>

      <Box mb={5}>
        <TextField 
          label="Dias por semana" 
          value={input.days}
          onChange={(event) => handleInput({days: event.target.value})}
        />
      </Box>

      {input.items.map((item) => {
        return (
          <Item  
            key={item.id} 
            item={item} 
            handleItemsInput={handleItemsInput} 
            removeItem={removeItem} 
            {...props.suggestions} 
          />
        )
      })}

      <Button 
        variant="contained" 
        color="default"
        size="small"
        onClick={appendNewItem}
      >
        Adicionar outro item
      </Button>
    </>
  )
}

export default Items