import React, {useEffect} from 'react'

import {ButtonPrimary} from '../../ui-kit/Button'
import Animate from '../../ui-kit/Animate'

import {TextBlock, Content, Bg, ButtonBlock} from '../../styles'

import pixel from '../../../../pixel'

import {Heading, Item, Paragraph} from './styles'

var Complete = () => {

  useEffect(
    () => {
      pixel.track({
        event: "VideoComplete"
      })
    }, []
  )

  const redirect = () => {
    window.location.href = `https://www.instagram.com/fit.n.livin`
  }

  return (
    <>
      <Content>
        <TextBlock>
          <Animate duration="2s" delay="2s">
            <Heading>Show!</Heading>
            <Paragraph>
              <Item style={{textAlign: "center", color: "white"}}>
                Em 24h entraremos em contato com você para mandar o resultado.
              </Item>
              <br/>
              <Item>
                Logo você vai poder enviar vídeos todo dia ou com a frequência 
                que você escolher. E com isso você pode:
              </Item>
              <Item>— Monitorar sua evolução com uma maior precisão</Item>
              <Item>— Documentar sua evolução de forma rápida e fácil através de um vídeo compilado</Item>
            </Paragraph>
          </Animate>

          <Animate duration=".8s" delay="6s">
            <ButtonBlock>
              <ButtonPrimary onClick={redirect}>
                Voltar para o instagram
              </ButtonPrimary>
            </ButtonBlock>
          </Animate>
        </TextBlock>
      </Content>

      <Animate duration="0s" delay="2s" out>
				<Animate duration="1s" delay=".1s">
					<Bg
						imageUrl='https://media1.giphy.com/media/j6e4O7ZyvEDEvg243d/giphy.gif?cid=ecf05e47790319fd3fb173851c2d59c7030d4117278497a7&rid=giphy.gif'
						opacity='1'
					/>
				</Animate>
			</Animate>

      <Animate duration="5s" delay="4.5s" out>
				<Animate duration="0s" delay="2s">
					<Bg
						imageUrl='https://media1.giphy.com/media/j6e4O7ZyvEDEvg243d/giphy.gif?cid=ecf05e47790319fd3fb173851c2d59c7030d4117278497a7&rid=giphy.gif'
						opacity='.3'
					/>
				</Animate>
			</Animate>
    </>
  )
  
}

export default Complete