import React, {useState} from 'react'

import Box from '@material-ui/core/Box'

import {ButtonBlock, Paragraph, Item, TextBlock, Content} from '../../styles'
import {Buttons, ButtonBack, ButtonPrimary, ButtonSecondary} from '../../ui-kit/Button'
import Animate from '../../ui-kit/Animate'

import {OptionsSelector} from '../../components'

var Question = ({input, answersInput, onBack, onChange, onNext}) => {

	const [canSubmit, setCanSubmit] = useState(null)

	var question
	var answers = []

	if (input.user.gender == "m") {
		question = "2. Você tem alguma pessoa que te inspira?"
		answers = [
			{id: 1, type: "text", label: "Sim, enviar Instagrams", placeholder: "Ex: @jumoraes.oficial", value: ""},
			{id: 2, type: "default", value: "Sou minha própria inspiração! Quero voltar a ter um corpo que já tive. (Você pode nos enviar essas fotos pelo WhatsApp quando terminar o questionário)"},
			{id: 3, type: "text", label: "Adicionar comentário", value: ""},
			{id: 4, type: "default", value: "Não"},
		]
	} else {
		question = "2. Você tem alguma musa inspiradora?"
		answers = [
			{id: 1, type: "text", label: "Sim, enviar Instagrams", placeholder: "Ex: @jumoraes.oficial", value: ""},
			{id: 2, type: "default", value: "Sou minha própria musa! Quero voltar a ter um corpo que já tive. (Você pode nos enviar essas fotos pelo WhatsApp quando terminar o questionário)"},
			{id: 3, type: "text", label: "Adicionar comentário", value: ""},
			{id: 4, type: "default", value: "Não"},
		]
	}

	return (
		<Content>
			<TextBlock>
				<form onSubmit={onNext}>
					<Animate duration="1s" delay=".3s">
						<Box fontSize={22} fontWeight={900} lineHeight={1.1} mb={2}>
							{question}
						</Box>
					</Animate>

					<Animate duration=".8s" delay="1s">
						<Paragraph>
							<Animate duration=".8s" delay="1s">
								<Item>Tem o corpo de alguma pessoa que você gostaria de nos mostrar pra dar uma ideia ainda melhor do que você gostaria de chegar?</Item>
							</Animate>
						</Paragraph>
					</Animate>

					<Animate duration="1s" delay="2s">
						<OptionsSelector
							type="checkbox"
							delay={2}
							position={0}
							onChange={onChange}
							question={question}
							answers={answers}
							answersInput={answersInput}
							onValid={(valid) => setCanSubmit(valid)}
						/>
					</Animate>

					<Animate duration="1s" delay="4s">
						<ButtonBlock>
							<Buttons>
								<ButtonBack onClick={onBack}>
									Voltar
								</ButtonBack>
								{canSubmit 
									? (
										<ButtonPrimary type="submit">
											Próximo →
										</ButtonPrimary>
									)
									: (
										<ButtonSecondary type="button">
											Próximo
										</ButtonSecondary>
									)
								}
							</Buttons>
						</ButtonBlock>
					</Animate>
				</form>
			</TextBlock>
		</Content>
	)
}

export default Question