import React from 'react'
import {format} from 'date-fns'
import {Page, Text, View, Image} from '@react-pdf/renderer'

import Logo from 'images/logo-symbol.png'
import Rainbow from 'images/rainbow.png'

import styles from './styles'

const Pdf1 = ({appointment}) => {

  const data = appointment.data[0].mealPlans

  const itemsGroupedByPeriod = (items) => {
    return items.reduce((acc, item) => {
      const indexFound = acc.findIndex(({period}) => period.name == item.period.name)

      if (indexFound >= 0) {
        return acc.map((accItem, index) => 
          index == indexFound 
          ? {...accItem, items: [...accItem.items, item]}
          : accItem
        )
      } else {
        return [
          ...acc,
          {
            period: item.period,
            items: [item]
          }
        ]
      }
    }, [])
  }

  return (
    data.map((mealPlan, index) => {
      return (
        <Page 
          size={{width: 1080, height: 1920}} 
          key={index} 
          style={styles.page} 
          wrap
        >
          <View style={styles.header}>
            <Image 
              src={Logo}
              style={styles.logo} 
            />
        
            <Text style={styles.date}>
              {format(appointment.date.toDate(), 'dd/MM/yyyy')}
            </Text>

            <View style={styles.userName}>
              {appointment.user.name.split(" ").map((name, index) => 
                <Text key={index} style={styles.userNameWord}>
                  {name}
                </Text>
              )}
            </View>

            <View style={styles.days}>
              <Text style={styles.daysNumber}>
                📅 {mealPlan.days} dias
              </Text>
              <Text style={styles.daysWeek}>
                por semana
              </Text>
            </View>
          </View>
          
          <View style={styles.content}>
            <View style={styles.rainbowWrap} fixed>
              <Image 
                src={Rainbow}
                style={styles.rainbow} 
              />
            </View>

            <View style={styles.periods}>
              {itemsGroupedByPeriod(mealPlan.items).map((itemGroup, index) => {
                return (
                  <View 
                    key={index}
                    style={styles.period}
                    wrap={false}
                  >
                    <View style={styles.periodLeft}>
                      <Text style={styles.periodTime}>{itemGroup.items[0].time.value}</Text>
                      <Text style={styles.periodTitle}>{itemGroup.period.name}</Text>
                    </View>

                    <View style={styles.periodRight}>
                      {itemGroup.items.map((item, index) => {
                        return (
                          <View 
                            key={index}
                            style={styles.section}
                          >
                            <View style={styles.sectionLine}>
                              <Text style={styles.text}>{item.meal.name}</Text>
                              <Text style={styles.quantity}>| {item.portion.name}</Text>
                            </View>  
                          </View>
                        )
                      })}
                    </View>
                  </View>
                )
              })}
            </View>
          </View>
          
          <View style={styles.footer} fixed>
            <Text style={styles.footerText}>
              {appointment.nutritionists.map((nutritionist, index) => {
                return (
                  <Text key={index}>
                    {nutritionist.name} {nutritionist.code} {index + 1 < appointment.nutritionists.length && " e "}
                  </Text>
                )
              })}
            </Text>
          </View>
        </Page>
      )
    })
  )
}

export default Pdf1