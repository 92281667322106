import React from 'react'

const Options = ({answersInput, type, position, onChange, question}) => {

  answersInput = answersInput[position] || []

  const onChangeTextValue = (event, answer) => {
    onChange({
      title: question,
      position,
      answers: answersInput.map((answerInput) => {
        if (answer.id == answerInput.id) {
          return {
            ...answerInput,
            value: event.target.value
          }
        } else {
          return answerInput
        }
      })
    })
  }

  const onReplaceAnswer = (answer) => {
    if (isAnswerSelected(answer)) {
      onChange({
        title: question,
        position,
        answers: []
      })
    } else {
      onChange({
        title: question,
        position,
        answers: [answer]
      })
    }
  }

  const addOrRemoveAnswer = (answer) => {
    if (isAnswerSelected(answer)) {
      answersInput = answersInput.filter(({id}) => id != answer.id)
    } else {
      answersInput = [
        ...answersInput,
        answer
      ]
    }

    onChange({
      title: question,
      position,
      answers: answersInput
    })
  }

  const onChangeAnswer = (answer) => {
    if (type == "radio") {
      onReplaceAnswer(answer)
    } else {
      addOrRemoveAnswer(answer)
    }
  }

  const isAnswerSelected = (answer) => {
    return answersInput.find(({id}) => id == answer.id) != null
  }

  const answerTextValue = (answer) => {
    
    return answersInput.filter(({id}) => id == answer.id).map(({value}) => value)[0] || ""
  }

  const canSubmit = () => {
    return answersInput.filter(({value}) => value.length > 0).length > 0
  }

  return {
    onChangeTextValue,
    onChangeAnswer,
    isAnswerSelected,
    answerTextValue,
    canSubmit
  }

}

export default Options