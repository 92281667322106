import styled from 'styled-components'

const Heading = styled.div`
  font-weight: 900;
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
`

const Paragraph = styled.div`
  font-weight: 500;
  font-size: 23px;
  line-height: 1.2;
  color: #a097a7;
`

const Item = styled.div`
  font-weight: 500;
  font-size: 21px;
  line-height: 1.2;
  margin-bottom: 20px;
`

export {
  Heading,
  Paragraph,
  Item
}