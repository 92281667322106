import React, {useMemo} from 'react'

import TextField from '@material-ui/core/TextField'

const Item = ({description, onChange}) => {

  return useMemo(() =>
    <TextField 
      label="Equivalente calórico" 
      multiline
      variant="outlined"
      fullWidth
      value={description}
      onChange={onChange}
    />,
    [description]
  )
}

export default Item