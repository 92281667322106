import React, {useState} from 'react'

import Box from '@material-ui/core/Box'

import {ButtonBlock, TextBlock, Content} from '../../styles'
import {Buttons, ButtonBack, ButtonPrimary, ButtonSecondary} from '../../ui-kit/Button'
import Animate from '../../ui-kit/Animate'

import {OptionsSelector} from '../../components'

var Question = ({answersInput, onBack, onChange, onNext}) => {

  const [canSubmit, setCanSubmit] = useState(null)

  const question = "4. Qual parte que você menos gosta no seu corpo?"
	const answers = [
    {id: 1, type: "default", value: "Abdômen"},
    {id: 2, type: "default", value: "Cintura"},
		{id: 3, type: "default", value: "Glúteos"},
    {id: 4, type: "default", value: "Pernas (quadríceps)"},
    {id: 5, type: "default", value: "Pernas (posterior da coxa)"},
    {id: 6, type: "default", value: "Panturrilha"},
    {id: 7, type: "default", value: "Braços (bíceps)"},
    {id: 8, type: "default", value: "Braços (tríceps)"},
		{id: 9, type: "default", value: "Ombro"},
    {id: 10, type: "default", value: "Costas"},
    {id: 11, type: "text", label: "Quero digitar...", value: ""}
  ]

	return (
		<Content>
			<TextBlock>
				<form onSubmit={onNext}>
					<Animate duration="1s" delay=".3s">
						<Box fontSize={22} fontWeight={900} lineHeight={1.1} mb={2}>
							{question}
						</Box>
					</Animate>

					<Animate duration="1s" delay="2s">
            <OptionsSelector
              type="checkbox"
              delay={2}
              position={0}
              onChange={onChange}
              question={question}
              answers={answers}
              answersInput={answersInput}
              onValid={(valid) => setCanSubmit(valid)}
            />
					</Animate>

					<Animate duration="1s" delay="4s">
						<ButtonBlock>
              <Buttons>
                <ButtonBack onClick={onBack}>
									Voltar
								</ButtonBack>
                {canSubmit
                  ? (
                    <ButtonPrimary type="submit">
                      Próximo →
                    </ButtonPrimary>
                  )
                  : (
                    <ButtonSecondary type="button">
                      Próximo
                    </ButtonSecondary>
                  )
                }
              </Buttons>
            </ButtonBlock>
					</Animate>
				</form>
			</TextBlock>
		</Content>
	)
}

export default Question