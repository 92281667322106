import React, {useMemo} from 'react'

import TextField from '@material-ui/core/TextField'

var Instructions = ({onChange, instructions}) => {
  
  return useMemo(() =>
    <TextField 
      label="Instruções" 
      multiline
      variant="outlined"
      fullWidth
      rows={20}
      value={instructions}
      onChange={(event) => onChange({instructions: event.target.value})}
    />,
    [instructions] 
  )

}

export default Instructions