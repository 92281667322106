import React from 'react'
import {Page, Text, View, Image} from '@react-pdf/renderer'
import emojiRegex from 'emoji-regex'

import Logo from 'images/logo-symbol.png'

import styles from './styles'

const Pdf3 = ({appointment}) => {
  
  const data = appointment.data[0].foods

  return (
    <Page 
      size={{width: 1080, height: 1920}} 
      style={styles.page} 
      wrap
    >
      <View style={styles.header} fixed>
        <Image 
          src={Logo}
          style={styles.logo} 
        />
      </View>
      
      <View style={styles.content} wrap>
        {data.sort((a, b) => a.sort > b.sort ? 1 : -1).map((food, index) => {
          if (food.items.length > 0) {
            return (
              <View 
                key={index}
                style={index > 0 && styles.food}
              >
                <View wrap={false}>
                  <View style={styles.foodTitle}>
                    {food.name.replace(emojiRegex(), '').trim().split(" ").map((name, index) => 
                      <Text 
                        key={index} 
                        style={index == 0 ? styles.foodTitleWordHead : styles.foodTitleWord}
                      >
                        {name}
                      </Text>
                    )}
                  </View>

                  <Text style={styles.foodDescription}>
                    🔄 {food.description}
                  </Text>

                  <Text style={styles.foodTh}>
                    📝 DESCRIÇÃO DOS ALIMENTOS / MEDIDA CASEIRA / QUANTIDADE (G)
                  </Text>
                </View>

                {food.items.sort((a, b) => 
                  (
                    (a.name.replace(emojiRegex(), '').trim() + a.description).localeCompare(
                      b.name.replace(emojiRegex(), '').trim() + b.description
                    )
                  )
                ).map((item, index) => {
                  return (
                    <View 
                      key={index}
                      style={styles.foodItem}
                    >
                      <Text style={styles.foodItemName}>
                        {item.name}
                      </Text>
                      {item.description.split(" ").map((descriptionWord, index) => {
                        return (
                          <Text key={index} style={styles.foodItemDescription}>
                            {descriptionWord}
                          </Text>
                        )
                      })}
                    </View>
                  )
                })}
              </View>
            )
          }
        })}
      </View>

      <View style={styles.footer} fixed>
        <Text style={styles.footerText}>
          {appointment.nutritionists.map((nutritionist, index) => {
            return (
              <Text key={index}>
                {nutritionist.name} {nutritionist.code} {index + 1 < appointment.nutritionists.length && " e "}
              </Text>
            )
          })}
        </Text>
      </View>
    </Page>
  )
}

export default Pdf3