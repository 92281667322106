import React, {useEffect, useState} from 'react'

import DateFnsUtils from '@date-io/date-fns'

import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers'

import database from 'database'

var Common = ({suggestions, appointment, inputGlobal, onChange, onSubmit}) => {

  const [submitting, setSubmitting] = useState(false)
  
  const [input, setInput] = useState({
    date: new Date(),
    user: {
      id: "",
      name: "",
      cpf: ""
    },
    nutritionists: []
  })

  useEffect(() => {
    onChange(input)
  }, [input])

  useEffect(() => {
    if (appointment) 
      setInput({
        date: appointment.date ? appointment.date.toDate() : input.date,
        user: appointment.user,
        nutritionists: appointment.nutritionists
      })
  }, [appointment])

  const handleInput = (data) => setInput(input => {
    return {...input, ...data}
  })

  const handleInputUser = (data) => setInput(input => {
    return {
      ...input,
      user: {
        ...input.user,
        ...data
      }
    }
  })

  const handleInputNutritionists = (data) => setInput(input => {
    return {
      ...input,
      nutritionists: data
    }
  })

  const submit = (event) => {
    event.preventDefault()
    
    setSubmitting(true)

    const {data, ...fields} = inputGlobal

    database
      .setAppointment(fields.id, fields, data ? data[0] : undefined)
      .then((data) => {
        onSubmit(data)
        setSubmitting(false)
      })
  }

  return (
    <form onSubmit={submit}>
      <Box mb={1}>
        <TextField 
          label="CPF" 
          required
          fullWidth
          onChange={(event) => handleInputUser({
            id: event.target.value, 
            cpf: event.target.value
          })}
          value={input.user.cpf}
        />
      </Box>

      <Box mb={5}>
        <TextField 
          label="Nome" 
          required
          fullWidth
          onChange={(event) => handleInputUser({name: event.target.value})}
          value={input.user.name}
        />
      </Box>

      <Box mb={5}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            required
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            label="Data de início do plano"
            value={input.date}
            onChange={(date) => {
              handleInput({date})
            }}
          />
        </MuiPickersUtilsProvider>
      </Box>

      <Box mb={5}>
        <Autocomplete
          multiple
          onChange={(event, value) => {
            handleInputNutritionists(value)
          }}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                label={option.name}
                {...getTagProps({ index })}
              />
            ))
          }
          getOptionSelected={(option, value) => option.name == value.name}
          options={suggestions.nutritionists}
          value={input.nutritionists}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} label="Nutricionistas" variant="outlined" />}
        />
      </Box>

      <Box py={2} position="fixed" bottom={0} width="100vw" style={{backgroundColor: "white"}} zIndex={2}>
        <Button 
          type="submit" 
          variant="contained"
          color="primary"
        >
          {submitting ? 'Atualizando...' : 'Atualizar'}
        </Button>
      </Box>
    </form>
  )
}

export default Common