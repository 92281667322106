import React from 'react'

import {Buttons, ButtonPrimary} from '../../ui-kit/Button'
import Animate from '../../ui-kit/Animate'

import {ButtonBlock, TextCenter, TextBlock, Paragraph, Content} from '../../styles'

var Text1 = ({onNext}) => {

  return (
    <Content>
      <TextBlock>
        <TextCenter>
          <Animate duration=".8s" delay=".3s">
            <Paragraph>
              Na nossa opinião, profissionais precisam conversar mais.
            </Paragraph>
          </Animate>
        </TextCenter>
      </TextBlock>

      <Animate duration=".3s" delay="1s">
        <ButtonBlock>
          <Buttons>
            <ButtonPrimary onClick={onNext}>
              Próximo
            </ButtonPrimary>
          </Buttons>
        </ButtonBlock>
      </Animate>
    </Content>
  )

}

export default Text1