import React, {useEffect, useState} from 'react'
import {format} from 'date-fns'

import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'

import Loading from 'components/Loading'

import database from 'database'

var Invoices = () => {
  
  const [loading, setLoading] = useState(true)
  const [invoices, setInvoices] = useState([])

  useEffect(() => {
    database
      .getInvoices()
      .then((data) => {
        setInvoices(data)
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }, [])

  if (loading) return <Loading />
  
  return (
    <Box my={5}>
      <Container maxWidth="xl">
        {invoices.map((invoice, index) => {
          return (
            <Box 
              key={index} 
              my={3}
              display="flex" 
              alignItems="center"
            >
              <Box>
                <Box><b>Status: </b> {invoice.status}</Box>
                <Box><b>Data: </b> {format(parseInt(invoice.created) * 1000 , 'dd/MM/yyyy')}</Box>
                <Box><b>Nome: </b> {invoice.customerName}</Box>
                <Box><b>E-mail: </b> {invoice.customerEmail}</Box>
                <Box><b>Telefone: </b> {invoice.customerPhone}</Box>
                <Box><b>CPF: </b> {invoice.customerTaxIds.find(({type}) => type == "br_cpf").value}</Box>
                <Box>
                  <b>Endereço: </b>
                  <div>
                    Cidade: {invoice.customerAddress.city} <br/>
                    País: {invoice.customerAddress.country} <br/>
                    Rua: {invoice.customerAddress.line1} <br/>
                    CEP: {invoice.customerAddress.postal_code} <br/>
                    Estado: {invoice.customerAddress.state} <br/>
                    Bairro: {invoice.customerNeighborhood}
                  </div>

                </Box>
                <Box><b>Total: </b>{invoice.total / 100}</Box>
                <Box><b>Plano: </b>{invoice.lines.data.map(({description}) => description).join(", ")}</Box>
              </Box>
            </Box>
          )
        })}
      </Container>
    </Box>
  )
  
}

export default Invoices
