import React, {useEffect, useState} from 'react'

import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import authentication from 'authentication'

var Auth = () => {
  
  const [submitting, setSubmitting] = useState(false)
  const [input, setInput] = useState({
    email: "",
    password: ""
  })

  useEffect(() => {
    authentication.onChange().then((user) => {
      if (user) {
        window.location.href = "/admin/appointments"
      } else {
        // User is signed out.
      }
    })
  }, [submitting])

  const handleInput = (data) => {
    setInput({
      ...input,
      ...data
    })
  }

  const signIn = (event) => {
    event.preventDefault()

    setSubmitting(true)

    authentication
      .signIn(input.email, input.password)
      .then(() => setSubmitting(false))
      .catch(() => setSubmitting(false))
  }
  
  return (
    <Box my={1}>
      <Container maxWidth="xl">
        <form
          onSubmit={signIn}
        >
          <Box mb={1}>
            <TextField 
              label="Email" 
              fullWidth
              onChange={(event) => handleInput({email: event.target.value})}
              value={input.email}
            />
          </Box>

          <Box mb={5}>
            <TextField 
              label="Senha" 
              type="password"
              required
              fullWidth
              onChange={(event) => handleInput({password: event.target.value})}
              value={input.password}
            />
          </Box>
          
          <Button 
            type="submit" 
            variant="contained"
            color="primary"
          >
            {submitting ? 'Entrando...' : 'Entrar'}
          </Button>
        </form>
      </Container>
    </Box>
  )
  
}

export default Auth
