import styled, {keyframes, createGlobalStyle} from 'styled-components'

const ScaleIn = keyframes`
 0% { transform: scale(1.1) }
 100% { transform: scale(1) }
`

const GlobalStyle = createGlobalStyle`
  *, ::after, ::before {
    box-sizing: border-box;
  }

  html {    
    font-size: 19px;
  }

  body {
    font-family: 'Montserrat', sans-serif;
    background-color: black;
    color: white;
    margin: 0;
  }
`

const Block = styled.div`
  width: 100vw;
  margin: auto;
  min-height: 100vh;

  @media(min-width: 900px) {
    width: 30vw;
  }
`

const Content = styled.div`
  position: relative;
  width: calc(100% - 20px);
  min-height: 100vh;
  margin: auto;
  z-index: 2;

  @media(min-width: 900px) {
    width: 100%;
  }
`

const TextBlock = styled.div` 
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding: 20px 0 100px 0;
`

const TextCenter = styled.div` 
  text-align: center;
`

const Paragraph = styled.div`
  font-weight: 500;
  font-size: 23px;
  line-height: 1.2;
  color: #99949C;
`

const ButtonBlock = styled.div`
  display: flex;
  position: fixed;
  left: 10px;
  bottom: 10px;
  justify-content: center;
  width: calc(100vw - 20px);
`

const Bg = styled.div`
  background-image: url('${props => props.imageUrl}');
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  transform: scale(1.5);
  opacity: ${props => props.opacity};
  animation: ${ScaleIn} .5s ease-out 0s forwards;

  @media(min-width: 900px) {
    width: 30vw;
  }
`

const Item = styled.div`
  font-weight: 500;
  font-size: 21px;
  line-height: 1.2;
  margin-bottom: 20px;
`

const Heading = styled.div`
  font-weight: 900;
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
`

const Disclaimer = styled.div`
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 5px;
  text-align: center;
  color: #56505A;
`

const Video = styled.video`
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
`

export {
  GlobalStyle,
  Block,
  Content,
  ButtonBlock,
  TextBlock,
  Bg,
  Item,
  TextCenter,
  Paragraph,
  Heading,
  Disclaimer,
  Video
}