import React from 'react'

import Box from '@material-ui/core/Box'

import {Buttons, ButtonSecondary} from '../../ui-kit/Button'
import Animate from '../../ui-kit/Animate'
import {ButtonBlock, TextBlock, Bg, TextCenter, Content} from '../../styles'
import {Headings, HeadingsCenter, HeadingSmall, HeadingLarge, HeadingHighlight} from './styles'

import Logo from 'images/logo-symbol.png'

const Wecolme = ({input, onNext}) => {

  return (
    <>
      <Content>
        <TextBlock>
          <Animate duration=".8s" delay=".3s">
            <Box mb={10}>
              <TextCenter>
                <img src={Logo} width={80} />
              </TextCenter>
            </Box>
          </Animate>
          
          <Animate duration="1s" delay="2s">
            <HeadingsCenter>
              <Headings>
                <HeadingSmall>OI</HeadingSmall>
                <Box display="flex" alignItems="flex-end">
                  <HeadingLarge>
                    {input.user.name}
                  </HeadingLarge>
                  <Box ml={1} mb={1}>
                    <HeadingSmall>VAMOS</HeadingSmall>
                    <HeadingSmall>NOS</HeadingSmall>
                  </Box>
                </Box>
                <HeadingHighlight>
                  CONHECER?
                </HeadingHighlight>
              </Headings>
            </HeadingsCenter>
          </Animate>

          <Animate duration="1s" delay="4s">
            <ButtonBlock>
              <Buttons>
                <ButtonSecondary onClick={onNext}>
                  Vamos!
                </ButtonSecondary>
              </Buttons>
            </ButtonBlock>
          </Animate>
        </TextBlock>
      </Content>
    </>
  )
}

export default Wecolme