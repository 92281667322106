import React, {useRef} from 'react'

import Box from '@material-ui/core/Box'
import Radio from '@material-ui/core/Radio'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import {ImageChecked} from '../../styles'

const OptionImageHorizontal = ({id, width, type, label, onChange, src, isSelected}) => {

  const elRef = useRef(null)
  
  var element

  if (type == "checkbox") {
    element = (
      <Checkbox
        value={id}
        checked={isSelected}
        onChange={onChange}
        color="primary"
      />
    )
  } else {
    element = (
      <Radio
        required
        value={id}
        checked={isSelected}
        onChange={onChange}
        color="primary"
      />
    )
  }

  const component = (
    <Box position="relative" width={`${width / 1.8}px`} flexShrink={0}>
      <Box 
        position="absolute" 
        px={3} 
        py={2} 
        style={{cursor: 'pointer', zIndex: 2}}
      >
        <FormControlLabel
          label={label}
          ref={elRef}
          control={element}
        />
      </Box>
      
      <Box 
        width="100%"
        borderRadius="8px"
        overflow="hidden"
        lineHeight={0}
      >
        <img 
          style={{width: "100%"}} 
          src={src} 
        />
      </Box>
    </Box>
  )

  return (
    <Box 
      mx={1}
      onClick={() => elRef.current.click()}
      position="relative"
    >
      {isSelected 
        ? <>{component} <ImageChecked /></>
        : component
      }
    </Box>
  )

}

export default OptionImageHorizontal