import React from 'react'

import {ButtonPrimary} from '../../ui-kit/Button'
import Animate from '../../ui-kit/Animate'

import {Video, ButtonBlock, TextBlock, Disclaimer, Paragraph, Content, Heading, Item} from '../../styles'

var Text2 = ({onNext, page, pages}) => {
  
  return (
    <Content>
      <TextBlock>
        <Animate duration=".8s" delay=".3s">
          <Disclaimer>
            Página {page} de {pages}
          </Disclaimer>
          <Heading>
            O mais confiável
          </Heading>
        </Animate>

        <Animate duration=".8s" delay="2.3s">
          <Paragraph>
            <Item>
              O método mais confiável que existe é o DEXA.
            </Item>
            <Item>
              Ele consiste em você entrar nessa máquina:
            </Item>
          </Paragraph>
        </Animate>

        <Animate duration=".8s" delay="4s">
          <br/><br/>
          <Video poster="https://media0.giphy.com/media/cnzP4cmBsiOrccg20V/giphy.gif" autoPlay muted loop playsInline controls={false}>
            <source src="https://i.imgur.com/BYjuRoX.mp4" type="video/mp4" />
          </Video>
          <br/><br/>
        </Animate>

        <Animate duration=".8s" delay="5s">
          <Paragraph>
            <Item>
              É parecido com uma de ressonância.
            </Item>

            <Animate duration=".2s" delay="6s">
              <Item>⚰️ Você fica por 20 minutos</Item>
            </Animate>
            <Animate duration=".2s" delay="6.5s">
              <Item>💰 Paga ±800 reais</Item>
            </Animate>
            <Animate duration=".2s" delay="7s">
              <Item>🍽 Jejum de 6h</Item>
            </Animate>
            <Animate duration=".2s" delay="7.5s">
              <Item>Ela vai te dar uma margem de erro de ±2%</Item>
            </Animate>
          </Paragraph>
        </Animate>
      </TextBlock>

      <Animate duration=".3s" delay="8s">
        <ButtonBlock>
          <ButtonPrimary onClick={onNext}>
            Mas tem + barato?
          </ButtonPrimary>
        </ButtonBlock>
      </Animate>
    </Content>
  )

}

export default Text2