import React, {useState} from 'react'

import Box from '@material-ui/core/Box'

import {ButtonBlock, TextBlock, Content} from '../../styles'
import {Buttons, ButtonBack, ButtonPrimary, ButtonSecondary} from '../../ui-kit/Button'
import Animate from '../../ui-kit/Animate'

import {OptionsSelector} from '../../components'

var Question = ({input, answersInput, onBack, onChange, onNext}) => {

	const [canSubmit, setCanSubmit] = useState(null)

  const question = "10. Qual é o seu ponto fraco?"
	var answers

	if (input.user.gender == "m") {
		answers = [
			{id: 1, type: 'default', value: "🥺 Exagerar no final de semana com a família"},
			{id: 2, type: 'default', value: "🥳 Beber aquela cerveja / vinho com os amigos"},
			{id: 3, type: 'default', value: "🎶 Não deixo passar um show!"},
			{id: 4, type: 'default', value: "😋 Eu ouvi tour gastronômico?"},
			{id: 5, type: 'default', value: "🍫 Sou fissurado por doces"},
			{id: 6, type: 'text', label: "Quero digitar...", value: ""}
		]
	} else {
		answers = [
			{id: 1, type: 'default', value: "🥺 Exagerar no final de semana com a família"},
			{id: 2, type: 'default', value: "🥳 Beber aquela cervejinha / vinho com os amigos"},
			{id: 3, type: 'default', value: "🎶 Não deixo passar um show!"},
			{id: 4, type: 'default', value: "😋 Eu ouvi tour gastronômico?"},
			{id: 5, type: 'default', value: "🍫 Sou fissurada por doces"},
			{id: 6, type: 'text', label: "Quero digitar...", value: ""}
		]
	}

	return (
		<Content>
			<TextBlock>
				<form onSubmit={onNext}>
					<Animate duration="1s" delay=".3s">
						<Box fontSize={22} fontWeight={900} lineHeight={1.1} mb={2}>
							{question}
						</Box>
					</Animate>

					<Animate duration="1s" delay="2s">
						<OptionsSelector
              type="checkbox"
              delay={2}
              position={0}
              onChange={onChange}
              question={question}
              answers={answers}
              answersInput={answersInput}
              onValid={(valid) => setCanSubmit(valid)}
            />
					</Animate>

					<Animate duration="1s" delay="4s">
						<ButtonBlock>
							<Buttons>
								<ButtonBack onClick={onBack}>
									Voltar
								</ButtonBack>
								{canSubmit
									? (
										<ButtonPrimary type="submit">
											Próximo →
										</ButtonPrimary>
									)
									: (
										<ButtonSecondary type="button">
											Próximo
										</ButtonSecondary>
									)
								}
							</Buttons>
						</ButtonBlock>
					</Animate>
				</form>
			</TextBlock>
		</Content>
	)
}

export default Question