import React, {useEffect, useState} from 'react'
import {format} from 'date-fns'

import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'

import Loading from 'components/Loading'

import database from 'database'

var Forms = () => {
  
  const [loading, setLoading] = useState(true)
  const [forms, setForms] = useState([])

  useEffect(() => {
    database
      .getForms()
      .then((data) => {
        setForms(data)
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }, [])

  if (loading) return <Loading />
  
  return (
    <Box my={5}>
      <Container maxWidth="xl">
        <Box mb={4}>Listando os últimos 100 resultados</Box>
        
        {forms.map((form, index) => {
          return (
            <form 
              action={`${process.env.API_URL}/forms`} 
              method="POST" 
              target="_blank"
            >
              <Box 
                key={index} 
                my={3}
                display="flex" 
                alignItems="center"
              >
                <Box>
                  <Box><b>Data:</b> {format(form.date.toDate(), 'dd/MM/yyyy')}</Box>
                  <Box><b>Nome: </b>{form.user.name}</Box>
                  <Box><b>Sexo: </b>{form.user.gender}</Box>
                  <Box><b>Instagram: </b>{form.user.username}</Box>
                </Box>

                <Box mb={2} display="flex" justifyContent="flex-end" ml="auto">
                  <Box mr={1}>
                    <Button 
                      mr={1}
                      variant="contained"
                      type="submit"
                    >
                      Ver respostas
                    </Button>
                  </Box>
                </Box>

                <input type="hidden" name="data" value={JSON.stringify(form.data)} />
              </Box>
            </form>
          )
        })}
      </Container>
    </Box>
  )
  
}

export default Forms