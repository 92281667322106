import React from 'react'

import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import ImportExportIcon from '@material-ui/icons/ImportExport'

var DataImport = ({type, onConfirm}) => {

  const sheets = [
    {type: 1, headers: ["time", "period", "meal", "portion", "variant", "days"], types: ["times", "periods", "meals", "portions"], fills: [0, 1]},
    {type: 2, headers: ["group", "name", "value", "unit"], fills: [0, 1]},
    {type: 3, headers: ["name", "description"]},
    {type: 4, headers: ["name", "composition", "description", "posology"]},
    {type: 5, headers: ["group", "description", "foodName", "foodPortion", "foodQuantity"], fills: [0, 1]}
  ]

  const process = async (event) => {
    const [header, rows, types] = await buildFromClipboard(event)
    const result = mapToJson(header, rows)

    onConfirm({
      data: result,
      types
    })
  }

  const mapToJson = (_, rows) => {
    const {headers, fills} = sheets.find((sheet) => type == sheet.type)

    var lastRefRow = null

    return rows.reduce((acc, row) => {
      return [
        ...acc, 
        headers.reduce((acc2, header, index) => {
          // Check if all fill column for current row is filled
          if (fills && fills.reduce((acc, fill) => acc && row[fill].length > 0, true)) {
            lastRefRow = row
          }
          
          return {
            ...acc2,
            // Fill with previous row column value at position "fills" if empty
            [header]: row[index] ? row[index] : lastRefRow ? lastRefRow[index] : row[index] || ""
          }
        }, {})
      ]
    }, [])
  }

  // TODO Multiline columns cannot have inner double quotes with breaklines...
  // TODO Cuidar se a ultima for vazia?

  // https://regexr.com/5di1r
  const sanitize = (text) => {
    return text
      .replace(/\r/g, '')
      .replace(/(?:\t|^)"([^"]|\n|"[^\n\t]+")*"/gm, (match, capture) => {
        // Exceto o ultimo se tiver
        return match.replace(/\n(?!$)/gm, "__")
      })
      .trim('\n')
  }

  const sanitizeColumn = (text) => {
    return text.trim()
      .replace(/^"([^\"]*)"$/m, '$1')
      .replace(/__ */gm, '\n')
      .replace(/  +/gm, ' ')
  }

  const transformColumn = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1)
  }

  const separateRows = (text) => {
    const {headers} = sheets.find((sheet) => type == sheet.type)

    return text.match(new RegExp(`(^(?:[^\t]*\t){${headers.length - 1}}[^\t]*$)`, 'gm'))
  }

  const mapTypes = (header, rows) => {
    const {headers, types} = sheets.find((sheet) => type == sheet.type)

    if (types) {
      return header.map((header, index) => {
        var data = []

        switch(headers[index]) {
          case "time": data = [...new Set(rows.map((row) => row[index]))].map((value) => { return {value: value} })
          break
          case "period": 
          case "meal":
          case "portion": data = [...new Set(rows.map((row) => row[index]))].map((value) => { return {name: value} })
          break
        }
        
        return {
          id: types[index],
          data
        }
      })
    } else {
      return {}
    }
  }

  const buildFromClipboard = (event) => {
    return new Promise((resolve, reject) => {
      var items = event.clipboardData.items
      var data
      
      for (var i = 0; i < items.length; i++) {
        if (items[i].type == 'text/plain') {
          data = items[i]
          break
        }
      }

      if (!data) reject()

      data.getAsString((text) => {
        text = sanitize(text)

        var rowsOfText = separateRows(text)
        var header = []
        var rows = []
        
        rowsOfText.forEach((rowAsText) => {
          var row = rowAsText.split('\t').map((colAsText) => transformColumn(sanitizeColumn(colAsText)))
          // The first row containing data is assumed to be the header
          if (header.length == 0) {
            // Remove empty columns
            while (row.length && !row[row.length - 1].trim()) row.pop()
            if (row.length == 0) return
            header = row
          } else {
            rows = [...rows, row.slice(0, header.length)]
          }
        })

        const types = mapTypes(header, rows)
        
        resolve([header, rows, types])
      })
    })
  }

  return (
    <Input 
      onPaste={process} 
      placeholder="Importar ctrl+c ctrl+v" 
      value="" 
      startAdornment={
        <InputAdornment position="start">
          <ImportExportIcon />
        </InputAdornment>
      }
    />
  )

}

export default DataImport