import React, {useState} from 'react'

import Box from '@material-ui/core/Box'

import {ButtonBlock, TextBlock, Content} from '../../styles'
import {Buttons, ButtonBack, ButtonPrimary, ButtonSecondary} from '../../ui-kit/Button'
import Animate from '../../ui-kit/Animate'

import {OptionsSelector} from '../../components'

var Question = ({answersInput, onBack, onChange, onNext}) => {

	const [canSubmit, setCanSubmit] = useState(null)

  const question = "8. Qual desses hábitos você acha que mais interferem em conseguir o que você quer?"
	const answers = [
		{id: 1, type: 'default', value: "Falta de motivação"},
		{id: 2, type: 'default', value: "Falta de organização"},
		{id: 3, type: 'default', value: "Falta de dinheiro"},
		{id: 4, type: 'default', value: "Falta de objetivo"},
		{id: 5, type: 'default', value: "Falta de tempo"},
		{id: 6, type: 'default', value: "Stress"},
		{id: 7, type: 'default', value: "Odeio comida fitness"},
		{id: 8, type: 'default', value: "Sou intolerante à lactose"},
		{id: 9, type: 'text', label: "Quero digitar...", value: ""}
  ]

	return (
		<Content>
			<TextBlock>
				<form onSubmit={onNext}>
					<Animate duration="1s" delay=".3s">
						<Box fontSize={22} fontWeight={900} lineHeight={1.1} mb={2}>
							{question}
						</Box>
					</Animate>

					<Animate duration="1s" delay="2s">
						<OptionsSelector
              type="checkbox"
              delay={2}
              position={0}
              onChange={onChange}
              question={question}
              answers={answers}
              answersInput={answersInput}
              onValid={(valid) => setCanSubmit(valid)}
            />
					</Animate>

					<Animate duration="1s" delay="4s">
						<ButtonBlock>
							<Buttons>
								<ButtonBack onClick={onBack}>
									Voltar
								</ButtonBack>
								{canSubmit
									? (
										<ButtonPrimary type="submit">
											Próximo →
										</ButtonPrimary>
									)
									: (
										<ButtonSecondary type="button">
											Próximo
										</ButtonSecondary>
									)
								}
							</Buttons>
						</ButtonBlock>
					</Animate>
				</form>
			</TextBlock>
		</Content>
	)
}

export default Question