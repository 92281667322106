import React, {useState} from 'react'

import Box from '@material-ui/core/Box'

import {ButtonBlock, TextBlock, Content} from '../../styles'
import {Buttons, ButtonBack, ButtonPrimary, ButtonSecondary} from '../../ui-kit/Button'
import Animate from '../../ui-kit/Animate'

import {OptionsSelector} from '../../components'

var Question = ({answersInput, onBack, onChange, onNext}) => {

	const [canSubmit, setCanSubmit] = useState(null)

  const question = "7. Como é a sua relação com 'começar e parar academia'?"
	const answers = [
		{id: 1, type: 'default', value: "Comecei há mais de 2 anos e não parei mais"},
		{id: 2, type: 'default', value: "Comecei há mais de 2 anos e só parei na quarentena"},
    {id: 3, type: 'default', value: "Já comecei e parei inúmeras vezes. Estou fazendo algo agora na quarentena na academia ou em casa"},
    {id: 4, type: 'default', value: "Já comecei e parei inúmeras vezes. Estava fazendo antes e parei pela quarentena"},
    {id: 5, type: 'default', value: "Já comecei e parei inúmeras vezes. Não estava fazendo antes e nem durante a quarentena"},
    {id: 6, type: 'default', value: "Nunca comecei"}
  ]

	return (
		<Content>
			<TextBlock>
				<form onSubmit={onNext}>
					<Animate duration="1s" delay=".3s">
						<Box fontSize={22} fontWeight={900} lineHeight={1.1} mb={2}>
							{question}
						</Box>
					</Animate>

					<Animate duration="1s" delay="2s">
						<OptionsSelector
              type="radio"
              delay={2}
              position={0}
              onChange={onChange}
              question={question}
              answers={answers}
              answersInput={answersInput}
              onValid={(valid) => setCanSubmit(valid)}
            />
					</Animate>

					<Animate duration="1s" delay="4s">
						<ButtonBlock>
							<Buttons>
								<ButtonBack onClick={onBack}>
									Voltar
								</ButtonBack>
								{canSubmit
									? (
										<ButtonPrimary type="submit">
											Próximo →
										</ButtonPrimary>
									)
									: (
										<ButtonSecondary type="button">
											Próximo
										</ButtonSecondary>
									)
								}
							</Buttons>
						</ButtonBlock>
					</Animate>
				</form>
			</TextBlock>
		</Content>
	)
}

export default Question