import React, {useEffect, useState} from 'react'
import styled, {keyframes} from 'styled-components'

const fadeInAnimation = keyframes`
 0% { opacity: 0 }
 100% { opacity: 1 }
`

const revealAnimation = keyframes`
 0% { top: -10px; }
 100% { top: 0px; }
`

const fadeOutAnimation = keyframes`
 0% { opacity: 1 }
 100% { opacity: 0 }
`

const StyledComponentOut = styled.div`
  position: relative;
  opacity: 1;
  width: ${props => props.width};
  height: 100%;
  z-index: ${props => props.zIndex};
  animation: ${fadeOutAnimation}  ${props => props.duration} ease-out ${props => props.delay} forwards
`

const StyledComponentIn = styled.div`
  position: relative;
  opacity: 0;
  z-index: ${props => props.zIndex};
  width: ${props => props.width};
  animation: ${fadeInAnimation}  ${props => props.duration} ease-out ${props => props.delay} forwards,
             ${revealAnimation} ${props => parseFloat(props.duration) / 2}s ease-out ${props => props.delay} forwards;
`

const Animate = ({children, ...props}) => {
  
  const [hidden, hide] = useState(props.out ? false : true)
  
  useEffect(() => {
    setTimeout(() => hide(props.out ? true : false), parseFloat(props.delay) * 1000)

    if (props.out) {
      setTimeout(() => hide(true), (parseFloat(props.delay) + parseFloat(props.duration) * 1000))
    }
  }, [])

  if (props.out) {
    if (hidden) {
      props = {
        ...props,
        zIndex: -1
      }
    }

    return (
      <StyledComponentOut {...props}>
        {children}
      </StyledComponentOut>
    )
  } else {
    if (hidden) {
      props = {
        ...props,
        zIndex: -1
      }
    }

    return (
      <StyledComponentIn {...props}>
        {children}
      </StyledComponentIn>
    )
  }
}

export default Animate