const track = ({event, payload = {}}) => {

  if (process.env.NODE_ENV == "production") {
    fbq('track', event, payload)
  } else {
    console.log("tracking...")
    console.log(event)
    console.log(payload)
  }

}

export default {track}