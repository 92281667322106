import React, {useEffect, useCallback, useState} from 'react'
import emojiRegex from 'emoji-regex'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

import Loading from 'components/Loading'

import {Common, Input1, Input2, Input3} from './components'

import {withRouter} from 'react-router'

import database from 'database'

var AppointmentEdit = ({match, history}) => {

  const [loadingAppointment, setLoadingAppointment] = useState(true)
  const [loadingTypes, setLoadingTypes] = useState(true)

  const [types, setTypes] = useState({})
  const [appointment, setAppointment] = useState(false)
  const [tab, changeTab] = useState(0)
  const [input, setInput] = useState({
    id: match.params.id == "_" ? null : match.params.id
  })

  const handleInput = useCallback((data) => {
    setInput(input => { return {...input, ...data}})
  }, [])

  const handleInputData = useCallback((data) => {
    setInput(input => { return {
      ...input, 
      data: input.data 
        ? [
          {
            ...input.data[0],
            ...data
          }
        ] 
        : [data]
    }})
  }, [])

  const handleSubmit = useCallback((data) => {
    setInput(input => { return {...input, id: data.id}})
  }, [])

  useEffect(() => {
    database.getTypes().then((data) => {
      setTypes(data)
      setLoadingTypes(false)
    })
  }, [])

  useEffect(() => {
    if (input.id) {
      history.replace({pathname: `/admin/appointments/${input.id}/edit`})
    }
  }, [input])

  useEffect(() => {
    database
      .getAppointment(match.params.id)
      .then((data) => {
        setLoadingAppointment(false)
        setInput(data)
        setAppointment(data)
      })
      .catch(() => {
        setLoadingAppointment(false)
        console.log("No appointment")
      })
  }, [])

  if (loadingAppointment || loadingTypes) return <Loading />
    
  return (
    <Box>
      <Container maxWidth="xl">
        <Grid container spacing={5}>
          <Grid item md={4}>
            <Box mt={10}>
              <Common 
                suggestions={{nutritionists: types.find(({id}) => id == "nutritionists").data}}
                appointment={appointment}
                inputGlobal={input}
                onChange={handleInput}
                onSubmit={handleSubmit}
              />
            </Box>
          </Grid>
          <Grid item md={8}>
            <Box display="flex" flexDirection="column">
              <Tabs
                value={tab}
                onChange={(event, value) => {
                  changeTab(value)
                }}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab label="Plano Alimentar" />
                <Tab label="Suplementos & Formulações" />
                <Tab label="Referência de Alimentos" />
              </Tabs>

              <Box flexGrow="1" py={5} my={5}>
                {tab == 0 && 
                  <Input1 
                    setTypes={setTypes}
                    types={types}
                    suggestions={{
                      meals: types.find(({id}) => id == "meals").data.sort((a, b) => a.name.replace(emojiRegex(), '').trim().localeCompare(b.name.replace(emojiRegex(), '').trim())),
                      portions: types.find(({id}) => id == "portions").data.sort((a, b) => a.name.localeCompare(b.name)),
                      periods: types.find(({id}) => id == "periods").data.sort((a, b) => a.name.localeCompare(b.name)),
                      times: types.find(({id}) => id == "times").data.sort((a, b) => a.value.localeCompare(b.value))
                    }}
                    inputGlobal={input}
                    appointment={appointment}
                    onChange={handleInputData}
                  />
                }

                {tab == 1 && 
                  <Input2 
                    suggestions={{
                      macroNutrients: types.find(({id}) => id == "macroNutrients").data.sort((a, b) => a.sort > b.sort ? 1 : -1),
                      minerals: types.find(({id}) => id == "minerals").data.sort((a, b) => a.name.localeCompare(b.name)),
                      vitamins: types.find(({id}) => id == "vitamins").data.sort((a, b) => a.name.localeCompare(b.name))
                    }}
                    inputGlobal={input}
                    appointment={appointment}
                    onChange={handleInputData} 
                  />
                }

                {tab == 2 && 
                  <Input3 
                    id={input.id}
                    suggestions={{
                      foods: types.find(({id}) => id == "foods").data.sort((a, b) => a.sort > b.sort ? 1 : -1).map((food) => {
                        return {
                          ...food,
                          items: food.items.sort((a, b) => 
                          (
                            (a.name.replace(emojiRegex(), '').trim() + a.description).localeCompare(
                              b.name.replace(emojiRegex(), '').trim() + b.description
                            )
                          )
                        )
                        }
                      })
                    }}
                    inputGlobal={input}
                    appointment={appointment}
                    onChange={handleInputData} 
                  />
                }
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
  
}

AppointmentEdit = withRouter(AppointmentEdit)

export default AppointmentEdit
