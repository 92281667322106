import styled from 'styled-components'


const Call = styled.div`
  font-weight: 900;
  font-size: 26px;
  line-height: 1.2;
  margin-bottom: 10px;
  text-align: center;
  text-transform: uppercase;
`

const ItemCall = styled.div`
  line-height: 1.2;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
`

export {
  Call,
  ItemCall
}