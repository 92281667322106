import React, {useEffect, useState} from 'react'

import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'

import DataImport from '../../../../components/DataImport'
import {isEqual, usePrevious} from '../../../../utils'

import {Items} from './components'

var Input1 = ({onChange, suggestions, types, setTypes, appointment, inputGlobal}) => {

  const [isOpen, setIsOpen] = useState(false)
  const [input, setInput] = useState([])
  
  const previousInput = usePrevious(input)

  useEffect(() => {
    if (inputGlobal && inputGlobal.data && inputGlobal.data.length > 0 && inputGlobal.data[0].mealPlans) {
      setInput(inputGlobal.data[0].mealPlans)
      return
    }

    if (appointment && appointment.data && appointment.data.length > 0 && appointment.data[0].mealPlans) {
      setInput(appointment.data[0].mealPlans)
      return
    }
  }, [appointment])

  useEffect(() => {
    if (previousInput && !isEqual(input, previousInput)) {
      onChange({mealPlans: input})
    }
  }, [input])

  const handleInput = (position, data) => {
    setInput(input => input.map((item, index) => 
        index == position 
        ? data
        : item
      )
    )
  }

  const appendInput = () => {
    setInput(input => [
      ...input,
      null
    ])
  }

  const mapDataImportToState = (result, index) => {
    setTypes([
      ...types.filter(({id}) => !result.types.map((type) => type.id).includes(id)),
      ...result.types
    ])

    setInput(input.map((item, index1) => {
      if (index1 == index) {
        return {
          variant: result.data[0].variant,
          days: result.data[0].days,
          items: result.data.map(({time, period, meal, portion}) => {
            return {
              id: Math.random(),
              time: {
                value: time
              },
              period: {
                name: period
              },
              meal: {
                name: meal
              },
              portion: {
                name: portion
              }
            }
          })
        }
      } else {
        return item
      }
    }))
  }

  return (
    <>
      {input.map((inputItem, index) => 
        <Box key={index} mb={5}>
          <Box mb={2}>
            <DataImport 
              type="1" 
              onConfirm={(data) => mapDataImportToState(data, index)}
            />
          </Box>

          <Items
            suggestions={suggestions} 
            input={inputItem} 
            onChange={(data) => handleInput(index, data)}
          />
        </Box>
      )}

      <Box 
        display="flex" 
        alignItems="center" 
        mt={5}
      >
        <Box mr={1}>
          <Button 
            variant="contained" 
            color="default"
            onClick={appendInput}
          >
            Adicionar outro plano
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default Input1