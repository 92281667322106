import './index.scss' 

import React from 'react'
import {Switch, Route} from 'react-router-dom'

import Auth from 'containers/Auth'
import Form from 'containers/Form'
import Forms from 'containers/Forms'
import Video from 'containers/Video'
import Videos from 'containers/Videos'
import Appointments from 'containers/Appointments'
import AppointmentEdit from 'containers/AppointmentEdit'
import AppointmentPdf from 'containers/AppointmentPdf'
import NotFound from 'containers/NotFound'
import Subscription from 'containers/Subscription'
import Invoices from 'containers/Invoices'

const App = () => {

  return (
    <Switch>
      <Route exact path="/notas" component={Invoices} />
      <Route exact path="/assinatura" component={Subscription} />
      <Route exact path="/form/:username" component={Form} />
      <Route exact path="/vamos-nos-conhecer/:username" component={Form} />
      <Route exact path="/video/:username" component={Video} />
      <Route exact path="/avaliacao-fisica-online/:username" component={Video} />
      <Route exact path="/admin/videos" component={Videos} />
      <Route exact path="/admin/forms" component={Forms} />
      <Route exact path="/admin/appointments" component={Appointments} />
      <Route exact path="/admin/appointments/:id/edit" component={AppointmentEdit} />
      <Route exact path="/admin/appointments/:id/pdf" component={AppointmentPdf} />
      <Route exact path="/admin" component={Auth} />
      <Route exact path="*" component={NotFound} />
    </Switch>
  )
  
}

export default App
