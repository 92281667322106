class Events {

  static send = (event) => {
    return fetch(`${process.env.API_URL}/events`, {
      method: 'POST',
      mode: "cors",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        name: event
      })
    })
  }

}

export default Events