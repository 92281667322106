import React, {useEffect, useState} from 'react'
import emojiRegex from 'emoji-regex'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import DataImport from '../../../../components/DataImport'
import {isEqual, usePrevious} from '../../../../utils'

import {Item, Description} from './components'

var Input3 = ({inputGlobal, suggestions, appointment, onChange}) => {
  
  const [input, setInput] = useState([])

  const previousInput = usePrevious(input)

  useEffect(() => {
    if (inputGlobal && inputGlobal.data && inputGlobal.data.length > 0 && inputGlobal.data[0].foods) {
      setInput(inputGlobal.data[0].foods)
      return
    }

    if (appointment && appointment.data && appointment.data.length > 0 && appointment.data[0].foods) {
      setInput(appointment.data[0].foods)
      return
    }
  }, [appointment])

  useEffect(() => {
    if (previousInput && !isEqual(input, previousInput)) {
      onChange({foods: input})
    }
  }, [input])

  const handleDescription = (food, value) => {
    setInput(input => {
      const inputFood = input.find(({name}) => name == food.name)

      if (inputFood) {
        return [
          ...input.map((inputFood) => 
            inputFood.name == food.name
            ? {
                ...inputFood,
                description: value
              }
            : inputFood
          )
        ]
      } else {
        var {items, ...other} = food
  
        return [
          ...input,
          {...other, items: [], description: value}
        ]
      }
    })
  }

  const mapDataImportToState = (result) => {
    setInput(result.data.reduce((acc, {group, description, foodName, foodPortion, foodQuantity}) => {
      var groupFind = acc.find(({name}) => name.toLowerCase() == group.toLowerCase())

      if (groupFind) {
        return [
          ...acc.filter(({name}) => name.toLowerCase() != group.toLowerCase()),
          {
            ...groupFind,
            items: [
              ...groupFind.items,
              {
                name: foodName,
                description: ` | ${foodPortion} | ${foodQuantity} `
              }
            ]
          }
        ]
      } else {
        return [
          ...acc,
          {
            name: group,
            ...suggestions.foods.find((food) => food.name.toLowerCase() == group.toLowerCase()),
            description: description,
            sort: acc.length + 1,
            items: [{
              name: foodName,
              description: ` | ${foodPortion} | ${foodQuantity} `
            }]
          }
        ]
      }
    }, []).filter(({name}) => name !== "Grupo"))
    // Filtered because of repeated headers from original spreadsheet
  }

  return (
    <>
      <Box mb={2}>
        <DataImport 
          type="5" 
          onConfirm={(data) => mapDataImportToState(data)}
        />
      </Box>

      {input.map((food, index) => {
        return (
          <Box 
            key={index}
            mt={5} 
            mb={5}
          >
            <Typography variant="h6" gutterBottom>
              {food.name}
            </Typography>
            
            <Box my={3}>
              <Description 
                description={food.description}
                onChange={(event) => handleDescription(food, event.target.value)}
              />
            </Box>

            <Box display="flex" flexDirection="row">
              <Box>
                <Typography variant="h6" gutterBottom>
                  Opções
                </Typography>

                {food.items.map((item, index) => {
                  return (
                    <Item key={index} item={item} />
                  )
                })}
              </Box>
            </Box>
          </Box>
        )
      })}
    </>
  )
}

export default Input3