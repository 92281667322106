import React, {useEffect, useState} from 'react'
import {PDFViewer, Document, Font} from '@react-pdf/renderer'

import Loading from 'components/Loading'

import {withRouter} from 'react-router'

import database from 'database'

import {Pdf1, Pdf2, Pdf3} from './components'

import FontBlack from 'fonts/Montserrat-Black.ttf'
import FontExtraBold from 'fonts/Montserrat-ExtraBold.ttf'
import FontBold from 'fonts/Montserrat-Bold.ttf'
import FontSemiBold from 'fonts/Montserrat-SemiBold.ttf'
import FontMedium from 'fonts/Montserrat-Medium.ttf'
import FontRegular from 'fonts/Montserrat-Regular.ttf'

Font.registerEmojiSource({
  format: 'png',
  url: 'https://raw.githubusercontent.com/iamcal/emoji-data/master/img-facebook-96/',
})

Font.register({ 
  family: 'Montserrat', 
  fonts: [
    {src: FontRegular, fontWeight: 400},
    {src: FontMedium, fontWeight: 500},
    {src: FontSemiBold, fontWeight: 600},
    {src: FontBold, fontWeight: 700},
    {src: FontExtraBold, fontWeight: 800},
    {src: FontBlack, fontWeight: 900}
  ]
})

var AppointmentPdf = ({match}) => {

  const [loading, setLoading] = useState(true)
  const [appointment, setAppointment] = useState(null)

   useEffect(() => {
    database
      .getAppointment(match.params.id)
      .then((data) => {
        setAppointment(data)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        console.log("No appointment")
      })
  }, [])

  if (loading) return <Loading />

  return (
    <PDFViewer>
      <Document>
        <Pdf1 appointment={appointment} />
        <Pdf2 appointment={appointment} />
        <Pdf3 appointment={appointment} />
      </Document>
    </PDFViewer>
  )
}

AppointmentPdf = withRouter(AppointmentPdf)

export default AppointmentPdf