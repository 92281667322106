import React from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'
import Box from '@material-ui/core/Box'

var Loading = () => {
  return (
    <Box 
      display="flex" 
      width="100%" 
      height="100vh"
      alignItems="center" 
      justifyContent="center"
    >
      <CircularProgress />
    </Box>
  )
}

export default Loading