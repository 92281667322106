import React from 'react'

import {ButtonPrimary} from '../../ui-kit/Button'
import Animate from '../../ui-kit/Animate'

import {ButtonBlock, TextBlock, Paragraph, Disclaimer, Content, Heading, Item} from '../../styles'

var Text3 = ({onNext, page, pages}) => {

  return (
    <Content>
      <TextBlock>
        <Animate duration=".8s" delay=".3s">
          <Disclaimer>
            Página {page} de {pages}
          </Disclaimer>
          <Heading>
            Como o nosso funciona
          </Heading>
        </Animate>

        <Animate duration=".8s" delay="2s">
          <Paragraph>
            <Animate duration=".8s" delay="2s">
              <Item>— Temos um banco de imagens com resultado de <b>muitos</b> exames de DEXA</Item>
            </Animate>
            <Animate duration=".8s" delay="2.5s">
              <Item>— Você manda um vídeo, vamos escanear o seu corpo</Item>
            </Animate>
            <Animate duration=".8s" delay="3s">
              <Item>
                — Desenvolvemos uma inteligência artificial que compara o seu biotipo de corpo com corpos 
                semelhantes que já foram escaneados no exame do DEXA, atingindo um resultado semelhante.
              </Item>
            </Animate>
          </Paragraph>
        </Animate>
      </TextBlock>

      <Animate duration=".3s" delay="4s">
        <ButtonBlock>
          <ButtonPrimary onClick={onNext}>
            Legal, vamos lá
          </ButtonPrimary>
        </ButtonBlock>
      </Animate>
    </Content>
  )

}

export default Text3