import React, {useEffect, useState} from 'react'

import Box from '@material-ui/core/Box'

import {ButtonBlock, TextBlock, Content} from '../../styles'
import {Buttons, ButtonBack, ButtonPrimary, ButtonSecondary} from '../../ui-kit/Button'
import Animate from '../../ui-kit/Animate'

import {OptionsSelector} from '../../components'

var Question = ({input, answersInput, onBack, onChange, onNext}) => {

  const [shouldJustify, setShouldJustify] = useState(false)
  const [canSubmit1, setCanSubmit1] = useState(null)
  const [canSubmit2, setCanSubmit2] = useState(null)

  const question = "6. Em relação à academia"
  var answers 

  if (input.user.gender == "m") {
    answers = [
      {id: 1, type: 'default', value: "É parte integral do meu estilo de vida"},
      {id: 2, type: 'default', value: "Gosto de ir, mas não sou obcecado"},
      {id: 3, type: 'default', value: "Indiferente"},
      {id: 4, type: 'default', value: "Não gosto, mas faço se precisar", justify: true},
      {id: 5, type: 'default', value: "Odeio, não gostaria de fazer de jeito nenhum", justify: true}
    ]
  } else {
    answers = [
      {id: 1, type: 'default', value: "É parte integral do meu estilo de vida"},
      {id: 2, type: 'default', value: "Gosto de ir, mas não sou obcecada"},
      {id: 3, type: 'default', value: "Indiferente"},
      {id: 4, type: 'default', value: "Não gosto, mas faço se precisar", justify: true},
      {id: 5, type: 'default', value: "Odeio, não gostaria de fazer de jeito nenhum", justify: true}
    ]
  }

  var subQuestion = "6.1. Quais coisas fazem você não gostar de academia?"
	var subAnswers = [
    {id: 1, type: 'default', value: "Acho chato/repetitivo"},
    {id: 2, type: 'default', value: "Odeio fazer aeróbico (esteira/bike)"},
    {id: 3, type: 'default', value: "Não gosto de instrutores"},
    {id: 4, type: 'default', value: "Não gosto de alguém me motivando ('vamos lá, mais uma, tá fácil...')"},
    {id: 5, type: 'default', value: "Tenho uma certa vergonha. Acho que não pertenço a esse lugar"},
    {id: 6, type: 'text', label: "Quero digitar...", value: ""}
  ]

  useEffect(() => {
    if (answersInput[0]) {
      if (answersInput[0][0].justify) {
        // Nothing
      } else {
        setShouldJustify(false)
        onChange({
          title: subQuestion,
          position: 1,
          answers: []
        })
      }
    }
  }, [JSON.stringify(answersInput)])

  const handleSubmit = (event) => {
    event.preventDefault()

    if (answersInput[0][0].justify) {
      setShouldJustify(true)
    } else {
      onNext(event)
    }
  }

	return (
		<Content>
			<TextBlock>
				<form onSubmit={handleSubmit}>
					<Animate duration="1s" delay=".3s">
						<Box fontSize={22} fontWeight={900} lineHeight={1.1} mb={2}>
							{question}
						</Box>
					</Animate>

					<Animate duration="1s" delay="2s">
            <OptionsSelector
              type="radio"
              delay={2}
              position={0}
              onChange={onChange}
              question={question}
              answers={answers}
              answersInput={answersInput}
              onValid={(valid) => setCanSubmit1(valid)}
            />
					</Animate>
          
          {!shouldJustify && (
            <Animate duration="1s" delay="4s">
              <ButtonBlock>
                <Buttons>
                  <ButtonBack onClick={onBack}>
                    Voltar
                  </ButtonBack>
                  {canSubmit1
                    ? (
                      <ButtonPrimary type="submit">
                        Próximo
                      </ButtonPrimary>
                    )
                    : (
                      <ButtonSecondary type="button">
                        Próximo
                      </ButtonSecondary>
                    )
                  }
                </Buttons>
              </ButtonBlock>
            </Animate>
          )}
				</form>
        
        {shouldJustify && (
          <>
            <br/><br/>
            <form onSubmit={onNext}>
              <Animate duration="1s" delay=".3s">
                <Box fontSize={22} fontWeight={900} lineHeight={1.1} mb={2}>
                  {subQuestion}
                </Box>
              </Animate>

              <Animate duration="1s" delay="2s">
                <OptionsSelector
                  type="checkbox"
                  delay={2}
                  position={1}
                  onChange={onChange}
                  question={subQuestion}
                  answers={subAnswers}
                  answersInput={answersInput}
                  onValid={(valid) => setCanSubmit2(valid)}
                />
              </Animate>

              <Animate duration="1s" delay="4s">
                <ButtonBlock>
                  <Buttons>
                    <ButtonBack onClick={onBack}>
                      Voltar
                    </ButtonBack>
                    {canSubmit2
                      ? (
                        <ButtonPrimary type="submit">
                          Próximo →
                        </ButtonPrimary>
                      )
                      : (
                        <ButtonSecondary type="button">
                          Próximo
                        </ButtonSecondary>
                      )
                    }
                  </Buttons>
                </ButtonBlock>
              </Animate>
            </form>
          </>
        )}
			</TextBlock>
		</Content>
	)
}

export default Question