import React from 'react'

import {ButtonPrimary, Buttons} from '../../ui-kit/Button'
import Animate from '../../ui-kit/Animate'

import {Heading, SubHeading, Item, Paragraph, TextBlock, Content, Bg, ButtonBlock} from '../../styles'

var Complete = () => {

  const redirect = () => {
    window.location.href = `https://www.instagram.com/fit.n.livin`
  }

  return (
    <>
      <Content>
        <TextBlock>
          <Animate duration="2s" delay="2s">
            <Heading>Ótimo!</Heading>
            <SubHeading>
              Já temos tudo que precisamos pra montar seu orçamento preliminar.
            </SubHeading>

            <Paragraph>
              <Item>Claro que depois conversando com os profissionais você pode alterar.</Item>
              <Item>Mas com isso podemos montar um plano de ação para melhorar sua vida. E te dar alternativas de planos mais rápidos ou mais longos.</Item>
              <Item>Estamos empolgados para começar 😄😄😄</Item>
							<Item>Se tiver alguma outra consideração, é só nos mandar no grupo do WhatsApp.</Item>
            </Paragraph>
          </Animate>

          <Animate duration=".8s" delay="6s">
            <ButtonBlock>
              <Buttons>
                <ButtonPrimary onClick={redirect}>
                  Voltar para o instagram
                </ButtonPrimary>
              </Buttons>
            </ButtonBlock>
          </Animate>
        </TextBlock>
      </Content>

      <Animate duration="0s" delay="2s" out>
				<Animate duration="1s" delay=".1s">
					<Bg
						imageUrl='https://i.imgur.com/TXgAwTk.gif'
						opacity='1'
					/>
				</Animate>
			</Animate>

      <Animate duration="5s" delay="4.5s" out>
				<Animate duration="0s" delay="2s">
					<Bg
						imageUrl='https://i.imgur.com/TXgAwTk.gif'
						opacity='.3'
					/>
				</Animate>
			</Animate>
    </>
  )
  
}

export default Complete