import firebase from '../firebase'

class Authentication {

  static onChange = () => {
    return new Promise((resolve) => {
      firebase.auth().onAuthStateChanged(resolve)
    })
  }

  static signIn = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(resolve)
        .catch(reject)
    })
  }
  
}

export default Authentication