import styled from 'styled-components'

const Button = styled.button`
  position: relative;
  border: none;
  display: flex;
  width: 100%;
  align-items: center;
  background: transparent;
  justify-content: center;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  outline: none;
  border-radius: 6px;
  font-size: 1rem;
  font-family: inherit;
  padding: .8em 1.25em;
  border: 1px solid transparent;
  line-height: 1.5;
  transition: background .3s;
  cursor: pointer;

  &:active {
    top: 1px;
  }

  @media(min-width: 900px) {
    width: 30vw;
    margin: auto;
  }
`

const ButtonPrimary = styled(Button)`
  background-color: #ec4121;
  color: #f5f0f0;
  font-weight: 700;
`

const ButtonSecondary = styled(Button)`
  background-color: white;
  color: black;
  font-weight: 700;
`

export {
  ButtonPrimary,
  ButtonSecondary
}