import React, {useEffect, useMemo} from 'react'

import Box from '@material-ui/core/Box'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'

var MacroNutrients = ({onChange, onChangeItems, energy, macroNutrients}) => {
  
  return useMemo(() =>
    <>
      <Box 
        display="flex" 
        alignItems="center" 
        flexDirection="row"
        mb={1}
      >
        <Box mr={1}>Energia:</Box> 
        <Input
          fullWidth
          value={energy}
          endAdornment={<InputAdornment position="end">kcal</InputAdornment>}
          onChange={(event) => onChange({energy: event.target.value})}
        />
      </Box>

      {macroNutrients.map((macroNutrient, index) => {
        return (
          <Box 
            key={index} 
            display="flex" 
            alignItems="center" 
            flexDirection="row"
            mb={1}
          >
            <Box mr={1} flexShrink={0}>{macroNutrient.name}:</Box> 
            <Input 
              fullWidth
              value={macroNutrient.value}
              endAdornment={<InputAdornment position="end">{macroNutrient.unit}</InputAdornment>}
              onChange={(event) => onChangeItems(
                {
                  name: macroNutrient.name, 
                  unit: macroNutrient.unit, 
                  value: event.target.value
                }, 
                index
              )}
            />
          </Box>
        )
      })}
    </>,
    [energy, macroNutrients] 
  )

}

export default MacroNutrients