import React from 'react'

import {ButtonPrimary} from '../../ui-kit/Button'
import Animate from '../../ui-kit/Animate'

import {ButtonBlock, TextBlock, Paragraph, Content, Heading, Item} from '../../styles'

var Text5 = ({onNext}) => {

  return (
    <Content>
      <TextBlock>
        <Animate duration=".8s" delay=".3s">
          <Heading>
            Como fazer?
          </Heading>
        </Animate>

        <Animate duration=".8s" delay="2s">
          <Paragraph>
            <Item>📸 Siga as instruções que iremos te mostrar logo mais e envie um vídeo</Item>
            <Item>😇 Não precisa mostrar do pescoço para cima se você não quiser</Item>
          </Paragraph>
        </Animate>
      </TextBlock>

      <Animate duration=".3s" delay="4s">
        <ButtonBlock>
          <ButtonPrimary onClick={onNext}>
            Ver instruções
          </ButtonPrimary>
        </ButtonBlock>
      </Animate>
    </Content>
  )

}

export default Text5