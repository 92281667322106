import React, {useRef} from 'react'

import Box from '@material-ui/core/Box'
import Radio from '@material-ui/core/Radio'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const OptionDefault = ({id, isSelected, onChange, type, value}) => {

  const elRef = useRef(null)

  var element

  if (type == "checkbox") {
    element = (
      <Checkbox
        value={id}
        checked={isSelected}
        onChange={onChange}
        color="primary"
        style={{marginTop: "-8px"}}
      />
    )
  } else {
    element = (
      <Radio
        required
        value={id}
        checked={isSelected}
        onChange={onChange}
        color="primary"
        style={{marginTop: "-8px"}}
      />
    )
  }

  return (
    <Box>
      <FormControlLabel
        py={2}
        ref={elRef}
        label={value}
        control={element}
        style={{alignItems: 'flex-start', width: '100%', padding: '10px 0'}}
      />
    </Box>
  )

}

export default OptionDefault