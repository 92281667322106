import React from 'react'

import Box from '@material-ui/core/Box'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

const Item = ({item, ...other}) => {

  return (
    <Box {...other}>
      <FormControlLabel
        control={<Checkbox checked color="primary" />}
        label={item.name + item.description}
      />
    </Box>
  )
}
  
export default Item