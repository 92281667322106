import {useRef, useEffect} from 'react'

const isEqual = (a, b) => {
  return JSON.stringify(a) === JSON.stringify(b)
}

const isObjectEmpty = (object) => {
  return !Object.values(object).some(x => {
    if (Array.isArray(x)) {
      return x.length > 0
    } else {
      return x !== null && x !== ''
    }
  })
}

const usePrevious = (value) => {
  const ref = useRef()
  
  useEffect(() => {
    ref.current = value
  })

  return ref.current
}

export {
  isObjectEmpty,
  usePrevious,
  isEqual
}