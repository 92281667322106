import React, {useState} from 'react'

import Box from '@material-ui/core/Box'

import {ButtonBlock, Paragraph, Item, TextBlock, Content} from '../../styles'
import {Buttons, ButtonBack, ButtonPrimary, ButtonSecondary} from '../../ui-kit/Button'
import Animate from '../../ui-kit/Animate'

import {OptionsSelector} from '../../components'

var Question = ({answersInput, onBack, onChange, onNext}) => {

	const [canSubmit, setCanSubmit] = useState(null)

  const question = "11. Para você, o que não pode faltar sentir que está emagrecendo e continuar vivendo feliz?"
	const answers = [
		{id: 1, type: 'image', label: "Pizza", value: "Pizza", image: "https://i.imgur.com/hXwzMrq.png"},
		{id: 2, type: 'image', label: "Churrasco", value: "Churrasco", image: "https://i.imgur.com/EvnZyos.png"},
		{id: 3, type: 'image', label: "Doces", value: "Doces", image: "https://i.imgur.com/kiLF0Nt.png"},
		{id: 4, type: 'image', label: "Hambúrguer", value: "Hambúrguer", image: "https://i.imgur.com/ZhXjYSz.png"},
		{id: 5, type: 'image', label: "Vinho", value: "Vinho", image: "https://i.imgur.com/DaFgkP8.png"},
		{id: 6, type: 'image', label: "Cerveja", value: "Cerveja", image: "https://i.imgur.com/y5vzm5v.png"},
		{id: 7, type: 'image', label: "Pipoca", value: "Pipoca", image: "https://i.imgur.com/PeZ8ALY.png"},
		{id: 8, type: 'text', label: "Quero digitar...", value: ""}
  ]

	return (
		<Content>
			<TextBlock>
				<form onSubmit={onNext}>
					<Animate duration="1s" delay=".3s">
						<Box fontSize={22} fontWeight={900} lineHeight={1.1} mb={2}>
							{question}
						</Box>
					</Animate>

					<Animate duration=".8s" delay="2.5s">
						<Paragraph>
							<Animate duration=".8s" delay="2.5s">
								<Item>Tudo que você marcar aqui vai ter na sua dieta!</Item>
							</Animate>
							<Animate duration=".8s" delay="3s">
								<Item>O essencial é você se sentir feliz enquanto faz.</Item>
							</Animate>
							<Animate duration=".8s" delay="3.5s">
								<Item>Se não beber faz você se sentir triste, você vai acabar desistindo. Então temos de dar um jeito de encaixar isso.</Item>
							</Animate>
						</Paragraph>
					</Animate>
					
					<Animate duration="1s" delay="5s">
						<OptionsSelector
              type="checkbox"
              delay={5}
              position={0}
              onChange={onChange}
              question={question}
              answers={answers}
              answersInput={answersInput}
              onValid={(valid) => setCanSubmit(valid)}
            />
					</Animate>

					<Animate duration="1s" delay="5s">
						<ButtonBlock>
							<Buttons>
								<ButtonBack onClick={onBack}>
									Voltar
								</ButtonBack>
								{canSubmit
									? (
										<ButtonPrimary type="submit">
											Próximo →
										</ButtonPrimary>
									)
									: (
										<ButtonSecondary type="button">
											Próximo
										</ButtonSecondary>
									)
								}
							</Buttons>
						</ButtonBlock>
					</Animate>
				</form>
			</TextBlock>
		</Content>
	)
}

export default Question