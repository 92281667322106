import React from 'react'

import Box from '@material-ui/core/Box'
import Radio from '@material-ui/core/Radio'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'

const OptionText = ({id, type, label, placeholder, valueInput, onChange, onChangeInput, isSelected}) => {

  var element

  if (type == "checkbox") {
    element = (
      <Checkbox
        value={id}
        checked={isSelected}
        onChange={onChange}
        color="primary"
        style={{marginTop: "-8px"}}
      />
    )
  } else {
    element = (
      <Radio
        required
        value={id}
        checked={isSelected}
        onChange={onChange}
        color="primary"
        style={{marginTop: "-8px"}}
      />
    )
  }

  return (
    <Box mb={2}>
      <FormControlLabel
        label={label}
        control={element}
        style={{alignItems: 'flex-start', width: '100%', padding: '10px 0'}}
      />

      <Box>
        {isSelected && (
          <TextField
            required
            autoFocus
            fullWidth
            multiline
            rows={4}
            variant="filled"
            label="Digite aqui"
            placeholder={placeholder ? placeholder : ""}
            value={valueInput}
            onChange={onChangeInput}
          />
        )}
      </Box>
    </Box>
  )

}

export default OptionText