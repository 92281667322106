import React, {useState} from 'react'
import {useLocation} from 'react-router'
import queryString from 'query-string'

import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'

import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles'

import {GlobalStyle, Heading, Disclaimer, Item, Block, ButtonBlock, Bg, TextBlock, Paragraph, Content} from './styles'

import {Buttons, ButtonPrimary} from './ui-kit/Button'
import Animate from './ui-kit/Animate'

var Subscription = () => {
  let location = useLocation()
  
  const status = queryString.parse(location.search).status
  const product = queryString.parse(location.search).product

  const [submitting, setSubmitting] = useState(false)
  
  const [input, setInput] = useState({
    name: "",
    cpf: "",
    cep: "",
    phone: "",
    email: "",
    product,
    successUrl: `${window.location.origin}/assinatura?status=success`,
    cancelUrl: `${window.location.origin}/assinatura?status=canceled`
  })

  const theme = createMuiTheme({
    typography: {
      fontFamily: "'Montserrat', sans-serif"
    },
    palette: {
      type: 'dark',
      primary: {
        light: '#757ce8',
        main: '#ec4121',
        dark: '#002884',
        contrastText: '#fff',
      }
    }
  })  

  if (status == "success") {
    return (
      <ThemeProvider theme={theme}>
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap" rel="stylesheet" />
        
        <GlobalStyle />
        
        <Block>
          <Content>
            <TextBlock>
              <Animate duration="2s" delay="2s">
                <Heading>Show!</Heading>
                <Paragraph>
                  <Item style={{textAlign: "center", color: "white"}}>
                    Sua assinatura foi iniciada!
                  </Item>
                  <br/>
                  <Item>
                    Logo você iniciará seu grupo exclusivo de acompanhamento com um time de profissionais incríveis
                  </Item>
                  <Item>Vamos juntos ser Fit Vivendo!</Item>
                </Paragraph>
              </Animate>
            </TextBlock>
          </Content>

          <Animate duration="0s" delay="2s" out>
            <Animate duration="1s" delay=".1s">
              <Bg
                imageUrl='https://media1.giphy.com/media/j6e4O7ZyvEDEvg243d/giphy.gif?cid=ecf05e47790319fd3fb173851c2d59c7030d4117278497a7&rid=giphy.gif'
                opacity='1'
              />
            </Animate>
          </Animate>

          <Animate duration="5s" delay="4.5s" out>
            <Animate duration="0s" delay="2s">
              <Bg
                imageUrl='https://media1.giphy.com/media/j6e4O7ZyvEDEvg243d/giphy.gif?cid=ecf05e47790319fd3fb173851c2d59c7030d4117278497a7&rid=giphy.gif'
                opacity='.3'
              />
            </Animate>
          </Animate>
        </Block>
      </ThemeProvider>
    )
  }

  const submit = (event) => {
    event.preventDefault()

    setSubmitting(true)

    fetch(`${process.env.API_URL}/checkouts`, {
      method: 'POST',
      mode: "cors",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(input)
    })
    .then((response) => {
      if (!response.ok) { throw response }

      return response.json()
    })
    .then((response) => {
      setSubmitting(false)
      redirectToCheckout(response.id)
    })
    .catch(async (response) => {
      const {error} = await response.json()

      setSubmitting(false)

      alert(error)
    })
  }

  const redirectToCheckout = (id) => {
    var stripe = Stripe(process.env.STRIPE_PUBLISHABLE_KEY)

    stripe.redirectToCheckout({
      sessionId: id
    })
    .then((result) => {
      if (result.error) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer.

        alert(result.error.message)
      }
    })
  }

  return (
    <ThemeProvider theme={theme}>
      <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap" rel="stylesheet" />
      
      <GlobalStyle />

      <form
        onSubmit={submit}
      >
        <Block>
          <Content>
            <TextBlock>
                <Animate duration=".8s" delay=".3s">
                <Disclaimer>
                  Como funciona
                </Disclaimer>
                <Heading>
                  Assinatura Fit n' Livin
                </Heading>
              </Animate>

              <Paragraph>
                <Animate duration=".8s" delay="1s">
                  <Item>
                    Você está a poucos passos de assinar o melhor acompanhamento 
                    nutricional e entrar para o <b>Fit N' Livin</b>
                  </Item>
                </Animate>
                <Animate duration=".8s" delay="1.5s">
                  <Item>
                    Para podermos oferecer um serviço de qualidade e emitirmos notas fiscais para 
                    você todo mês, pedimos que preencha esta etapa antes:
                  </Item>
                </Animate>

                <Animate duration=".8s" delay="2s">
                  <br/>
                    
                  <Box mb={2}>
                    <TextField 
                      variant="outlined"
                      style={{width: "100%"}}
                      label="Nome Completo" 
                      placeholder="Digite seu nome completo"
                      required
                      onChange={(event) => setInput({...input, name: event.target.value})}
                      value={input.name}
                      inputProps={{
                        autoComplete: 'off'
                      }}
                    />
                  </Box>

                  <Box mb={2}>
                    <TextField
                      variant="outlined"
                      style={{width: "100%"}}
                      label="E-mail" 
                      placeholder="Digite seu e-mail"
                      helperText="Escolha um e-mail que você usa."
                      required
                      onChange={(event) => setInput({...input, email: event.target.value})}
                      value={input.email}
                      inputProps={{
                        autoComplete: 'off'
                      }}
                    />
                  </Box>

                  <Box mb={2}>
                    <TextField
                      variant="outlined"
                      style={{width: "100%"}}
                      label="CPF" 
                      placeholder="045.988.448-87"
                      required
                      onChange={(event) => setInput({...input, cpf: event.target.value})}
                      value={input.cpf}
                      inputProps={{
                        autoComplete: 'off'
                      }}
                    />
                  </Box>

                  <Box mb={2}>
                    <TextField 
                      variant="outlined"
                      style={{width: "100%"}} 
                      label="Número do Celular" 
                      placeholder="(11) 91234-5678"
                      required
                      onChange={(event) => setInput({...input, phone: event.target.value})}
                      value={input.phone}
                      inputProps={{
                        autoComplete: 'off'
                      }}
                    />
                  </Box>

                  <Box mb={2}>
                    <TextField 
                      variant="outlined"
                      style={{width: "100%"}}
                      label="CEP" 
                      placeholder="88010-641"
                      required
                      onChange={(event) => setInput({...input, cep: event.target.value})}
                      value={input.cep}
                      inputProps={{
                        autoComplete: 'off'
                      }}
                    />
                  </Box>
                </Animate>
              </Paragraph>
            </TextBlock>

            <Animate duration=".3s" delay="1s">
              <ButtonBlock>
                <Buttons>
                  {submitting 
                    ? (
                      <ButtonPrimary 
                        disabled
                      >
                        Confirmando...
                      </ButtonPrimary>
                    ) 
                    : (
                      <ButtonPrimary 
                        type="submit"
                      >
                        Confirmar
                      </ButtonPrimary>
                    )
                  }
                </Buttons>
              </ButtonBlock>
            </Animate>
          </Content>
        </Block>
      </form>
    </ThemeProvider>
  )
  
}

export default Subscription


