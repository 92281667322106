import React, {useState} from 'react'

import Box from '@material-ui/core/Box'

import {ButtonBlock, TextBlock, Content} from '../../styles'
import {Buttons, ButtonBack, ButtonPrimary, ButtonSecondary} from '../../ui-kit/Button'
import Animate from '../../ui-kit/Animate'

import {OptionsSelector} from '../../components'

var Question = ({input, answersInput, onBack, onChange, onNext}) => {

	const [canSubmit, setCanSubmit] = useState(null)

	var question
	var answers = []

	if (input.user.gender == "m") {
		question = "3. Qual parte você mais gostaria de valorizar agora?"
		answers = [
			{id: 1, type: "image", label: "Abdômen", value: "Abdômen", image: "https://i.imgur.com/I1TN8Se.png"},
			{id: 2, type: "image", label: "Glúteos", value: "Glúteos", image: "https://i.imgur.com/8Cei6xH.jpg"},
			{id: 3, type: "image", label: "Pernas (quadríceps)", value: "Pernas (quadríceps)", image: "https://i.imgur.com/9gGA7oc.png"},
			{id: 4, type: "image", label: "Pernas (posterior da coxa)", value: "Pernas (posterior da coxa)", image: "https://i.imgur.com/9c7QYzT.png"},
			{id: 5, type: "image", label: "Panturrilha", value: "Panturrilha", image: "https://i.imgur.com/A3ql1Tg.png"},
			{id: 6, type: "image", label: "Braços (bíceps)", value: "Braços (bíceps)", image: "https://i.imgur.com/XL9TOPj.png"},
			{id: 8, type: "image", label: "Braços (tríceps)", value: "Braços (tríceps)", image: "https://i.imgur.com/OFs2N6z.png"},
			{id: 9, type: "image", label: "Ombro", value: "Ombro", image: "https://i.imgur.com/Lb2KDY3.png"},
			{id: 10, type: "image", label: "Costas", value: "Costas", image: "https://i.imgur.com/9PQdnkd.png"},
			{id: 11, type: "image", label: "Cintura", value: "Cintura", image: "https://i.imgur.com/889kby7.png"},
			{id: 12, type: "image", label: "Peito", value: "Peito", image: "https://i.imgur.com/34i8g2Y.png"},
			{id: 13, type: "text", label: "Quero digitar...", value: ""}
		]
	} else {
		question = "3. Qual parte você mais gostaria de valorizar agora?"
		answers = [
			{id: 1, type: "image", label: "Abdômen", value: "Abdômen", image: "https://i.imgur.com/wdmCX7c.png"},
			{id: 2, type: "image", label: "Glúteos", value: "Glúteos", image: "https://i.imgur.com/6OdRNJb.png"},
			{id: 3, type: "image", label: "Pernas (quadríceps)", value: "Pernas (quadríceps)", image: "https://i.imgur.com/CuMDrJh.png"},
			{id: 4, type: "image", label: "Pernas (posterior da coxa)", value: "Pernas (posterior da coxa)", image: "https://i.imgur.com/Atdbcf0.png"},
			{id: 5, type: "image", label: "Panturrilha", value: "Panturrilha", image: "https://i.imgur.com/MVUZQJi.png"},
			{id: 6, type: "image", label: "Braços (bíceps)", value: "Braços (bíceps)", image: "https://i.imgur.com/RCfcCFr.png"},
			{id: 8, type: "image", label: "Braços (tríceps)", value: "Braços (tríceps)", image: "https://i.imgur.com/NIb9UPm.png"},
			{id: 9, type: "image", label: "Ombro", value: "Ombro", image: "https://i.imgur.com/pdPWwom.png"},
			{id: 10, type: "image", label: "Costas", value: "Costas", image: "https://i.imgur.com/rBe8gwP.png"},
			{id: 11, type: "image", label: "Cintura", value: "Cintura", image: "https://i.imgur.com/ZBegbJz.png"},
			{id: 12, type: "text", label: "Quero digitar...", value: ""}
		]
	}

	return (
		<Content>
			<TextBlock>
				<form onSubmit={onNext}>
					<Animate duration="1s" delay=".3s">
						<Box fontSize={22} fontWeight={900} lineHeight={1.1} mb={2}>
							{question}
						</Box>
					</Animate>

					<Animate duration="1s" delay="2s">
						<OptionsSelector
							type="checkbox"
							delay={2}
							position={0}
							onChange={onChange}
							question={question}
							answers={answers}
							answersInput={answersInput}
							onValid={(valid) => setCanSubmit(valid)}
						/>
					</Animate>

					<Animate duration="1s" delay="4s">
						<ButtonBlock>
							<Buttons>
								<ButtonBack onClick={onBack}>
									Voltar
								</ButtonBack>
								{canSubmit 
									? (
										<ButtonPrimary type="submit">
											Próximo →
										</ButtonPrimary>
									)
									: (
										<ButtonSecondary type="button">
											Próximo
										</ButtonSecondary>
									)
								}
							</Buttons>
						</ButtonBlock>
					</Animate>
				</form>
			</TextBlock>
		</Content>
	)
}

export default Question